<template>
    <div>
        <div v-if="show" class="backdrop">
            <div class="body">
      <div class="position-absolute">
        <a href="javascript:;" @click.stop="closeModal" class="p-2">
          <i class="lsicon arrow_left_red"></i>
        </a>
      </div>

    <div v-if="showblockonly" class="text-center pt-5">
        <p class="mb-4 fw-600">{{$t('block_confirm_h').replace('[first_name]', target_name)}}?</p>
        <div class=" mb-3">
            
                <b-button variant="ls-red2" @click="sendBlock" pill class="px-5">
                    {{$t('block_user')}}
                </b-button>
            </div>

            <!-- <div class=" mb-3">
            
                <b-button variant="light" @click="sendBlock" pill class="px-5">
                    {{$t('block_user')}}
                </b-button>
            </div> -->
    </div>

      <div v-if="!isReport && !showblockonly">
            <div class="text-center mb-3">
                <b-button variant="ls-red2" @click="sendBlock" pill class="px-5">
                    {{$t('block_user')}}
                </b-button>
            </div>
            <div class="text-center">
                <b-button variant="ls-red2" @click="showReport" pill class="px-5">
                    {{$t('m_report_member')}}
                </b-button>
                
            </div>
      </div>
            


            <div v-if="isReport && !showblockonly" class="py-3">
            <div class="text-center fw-600">{{$t('m_report_member')}}</div>
            <div class="my-3 text-muted">{{$t('report_member_desc')}}</div>
                <div class="mb-3">
                    <textarea v-model="report_content" rows="4" class="w-100 border p-3" placeholder="write your report here"></textarea>
                </div>

                <div class="text-center">
                <b-button variant="ls-red2" @click="sendReport" pill class="px-5" :disabled="!report_content">
                    {{$t('button_sendfriend_send')}}
                </b-button>
                
            </div>
            </div>
            </div>
            </div>
            

      
    </div>
</template>
<script>
export default {
    name: "block-report-user",
    data(){
        return {
            show: false,
            isReport:false,
            report_content: '',
            target_id: 0,
            showblockonly: false,
            target_name: ''
        }
    },
    mounted(){

    },
    methods: {
        sendBlock(){
            let self = this;
            if(!self.target_id){
                return false;
            }
            self.$layer.loading();
            let pdata = new FormData();
            pdata.append('target_client_id', self.target_id)
            self.$api.postBlockUser(pdata, self.$store.state)
            .then(rs=>{
                self.closeModal()
                self.$layer.closeAll("loading");
                self.$emit('afterBlock', {type: 'block'})
            })
        },
        sendReport(){
            let self = this;
            console.log('sendReport', self.target_id,self.report_content)
            if(!self.target_id || !self.report_content){
                return false;
            }
            self.$layer.loading();
            let pdata = new FormData();
            pdata.append('target_client_id', self.target_id)
            pdata.append('report_content', self.report_content)
            self.$api.postReportUser(pdata, self.$store.state)
            .then(rs=>{
                self.closeModal()
                self.$layer.closeAll("loading");
                self.$emit('afterBlock', {type: 'report'})
            })
        },
        showReport(){
            this.isReport = true;
        },
        showBlockOnly(data){
            let self = this;
            self.show = true;
            self.showblockonly = true
            self.target_id = _.get(data, 'en_id')
            self.target_name = _.get(data, 'first_name')
        },
        showReportOnly(data){
            let self = this;
            self.show = true;
            self.showblockonly = false
            self.isReport = true
            self.target_id = _.get(data, 'en_id')
            // self.target_name = _.get(data, 'name')
        },
        showModal(data){
            let self = this;
            self.show = true;
            self.target_id = _.get(data, 'target_id')
            // console.log('show', data)
        },
        closeModal() {
            let self = this;
            // console.log('closeModal...')
            self.show = false;
            self.isReport = false
            self.showblockonly = false

            return false;

            //   self.client = null;
            //   self.label_button = null;
            //   self.labelHtmlText = null;
        },
    }
}
</script>
<style scoped>
.backdrop{
    position: fixed;
    width: 100%;
    height: 100vh;
    top:0;
    left:0;
    right: 0;
    bottom:0;
    background: rgba(0,0,0,.4);
}

.body{
    max-width: 500px;
    background: #fff;
    border-radius: 8px;
    /* margin: 0 auto; */
    position: absolute;
    min-width: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 15px;
}
</style>