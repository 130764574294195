<template>
  <div class="bg-faf text-1d min-h100">
    <topName ref="topName" v-if="showTopNav" :pageTitle="$t('Dates')" :dateCredit="clientDateCredit"
      :eventTicket="clientEventTicket" :showMobileTop="false" />

    <div class="chatWin">
      <div v-if="isNoDates" class="text-center py-5">
        <p>{{ $t("no_date_found") }}</p>
        <div class="mt-3">
          <b-button @click="goToSearchPage" variant="ee1" class="mb-2 text-capitalize px-5">{{ $t("search_txt")
          }}</b-button>
        </div>
      </div>

      <div v-if="showLeftPlan" class="leftPlan" style="display: block">
        <div id="listContent" class="chatListBox" :style="listContentStyle">
          <div class="px-3">
            <ul class="list-group list-group-flush">
              <li class="p-2 bg-faf border-b-d7c">
                <div class="d-flex justify-content-star">
                  <div>
                    <i class="fa-regular fa-magnifying-glass text-ee1 fs-28"></i>
                  </div>
                  <div class="w-100">
                    <b-input type="text" v-model="listQuery.search_text" @keyup.enter="goSearch"
                      class="v6-2 w-100 pl-3 border-0" style="height: 32px" data-id="5668"
                      :placeholder="$t('search_matches')" />
                  </div>
                  <div v-if="listQuery.search_text">
                    <i @click="clearSearch" class="lsicon cursor">X</i>
                  </div>
                </div>
              </li>

              <div v-if="hasContacts" style="margin-top: 1px; overflow: hidden">
                <li v-for="item in contacts" :key="`${item.target_client_id}`" @click="chatToClient(item)"
                  class="position-relative px-2 py-3 border-b-d7c bg-faf" :class="targetClientId == item.target_client_id ? 'bg-light' : ''
                    " @touchstart.capture="touchStart(item.target_client_id)" @touchmove.capture="touchMove"
                  @touchend.capture="touchEnd">
                  <div style="transition: 0.2s ease 0s" :style="item.target_client_id == activeId ? swipeStyle : ''">
                    <div class="d-flex justify-content-start">
                      <div class="headImgBox mr-3" :class="!item.can_chat ? 'opacity-5' : ''">
                        <b-img class="w-100" :src="item.target.photo_url"></b-img>
                      </div>
                      <div class="" :style="rowStyle">
                        <div class="d-flex justify-content-between mb-2">
                          <p class="font-weight-bold font-14">
                            {{ item.target.first_name }}
                          </p>
                          <p class="small text-muted font-weight-light">
                            {{ item.sent_time | messageTimeFormat }}
                          </p>
                        </div>

                        <p v-if="item.type == 2" class="text-truncate text-muted font-14">
                          [{{ $t("Photo") }}]
                        </p>
                        <p v-else class="text-truncate text-muted font-14">
                          {{ item.msg }}
                        </p>

                        <div v-if="item.unread_count > 0" class="redBot">
                          {{ item.unread_count > 99 ? 99 : item.unread_count }}
                        </div>
                      </div>
                      <div v-if="showReportBlockBtn &&
                        item.target_client_id != matchmakerChatId
                        " :id="`${item.target_client_id}-report`" class="ml-2">
                        <b-button @click.stop="reportUser(item)" variant="warning" class="rounded-0"
                          style="height: 100%">{{ $t("m_report_member") }}</b-button>
                      </div>
                      <div v-if="showReportBlockBtn &&
                        item.target_client_id != matchmakerChatId
                        " :id="`${item.target_client_id}-block`">
                        <b-button @click.stop="blockUser(item)" variant="danger" class="rounded-0" style="height: 100%">{{
                          $t("block_user") }}</b-button>
                      </div>
                    </div>
                  </div>
                </li>
              </div>
              <div v-if="!hasContacts && isLoadingContact">
                <div v-for="n in skeletonTotal" :key="n" class="position-relative my-4">
                  <div style="width: 50px; position: absolute">
                    <div class="headImgBox">
                      <div class="b-skeleton b-skeleton-avatar b-skeleton-animate-fade"></div>
                    </div>
                  </div>
                  <div class="w-100">
                    <div style="
                        height: 50px;
                        padding-left: 55px;
                        padding-right: 10px;
                      ">
                      <div class="d-flex justify-content-between mb-2">
                        <div class="b-skeleton b-skeleton-text b-skeleton-animate-fade" style="width: 30%"></div>
                        <div class="b-skeleton b-skeleton-text b-skeleton-animate-fade" style="width: 30%"></div>
                      </div>

                      <div class="b-skeleton b-skeleton-text b-skeleton-animate-fade" style="width: 100%"></div>
                    </div>
                  </div>
                </div>
              </div>
              <li class="list-group-item text-center border-0" v-if="hasContacts && isLoadingContact">
                {{ $t("Loading") }}...
              </li>
              <li class="list-group-item text-center border-0" v-if="!isLoadingContact && isEnd">
                {{ $t("no_more_found") }}
              </li>
            </ul>
            <!-- <div v-if="showTopNav && !showRightPlan" style="height: 76px"></div> -->
          </div>
        </div>
      </div>

      <div v-if="showRightPlan" id="contentBox" class="rightPlan" :style="contentBoxStyle">
        <!--content head-->
        <div id="contentHead" :style="`height: ${isChatConfirmed ? 92 : 67}px`" class="border-bottom position-relative">
          <div v-if="targetClient" class="d-flex justify-content-start p-2">
            <div class="d-flex align-items-center mr-3 backbtn2">
              <a data-dd="123" @click.stop="backToList" href="javascript:;">
                <i class="fa-regular fa-chevron-left text-ee1 fs-24"></i>
              </a>
            </div>
            <div>
              <div @click="viewProfile" class="headImgBox" style="cursor: pointer">
                <b-img class="w-100" :src="targetClient.photo_url"></b-img>
              </div>
            </div>
            <div @click="viewProfile" class="d-flex align-items-center ml-3" style="cursor: pointer">
              <div class="font-14">
                <p class="fw-600 text-ee1">
                  {{ targetClient.first_name }}
                  <!-- <span v-if="targetClientId != employeeTag"
                    >({{ targetClient.age }},
                    {{ targetClient.job_title }})</span
                  > -->
                  <!-- <i
                    v-if="targetClient.is_verified == 1"
                    class="lsicon verified_bule lsicon-14"
                    style="top: 3px"
                  ></i> -->
                </p>
                <!-- <p class="small font-weight-normal text-white">
                 
                  {{ targetClient.last_active_text }}
                </p> -->
                <p v-if="matchTimeHtml" class="small font-weight-normal" v-html="matchTimeHtml"></p>
                <!-- <p v-else class="small font-weight-normal">
                  {{ targetClient.last_active_text }}
                </p> -->
              </div>
            </div>
          </div>
          <!-- -->
          
        </div>

        <!--content body-->
        <div id="contentBody" class="mt-3 p-2 pb-4" style="height: auto; overflow: scroll">
          <!-- <div class="profileCard">
            <profileViewCom ref="profileViewCom" :isCard="true" />
          </div> -->
          <div v-if="dateTimeHtml" class="text-center small mb-3" v-html="dateTimeHtml"></div>
          <div v-if="targetClientId == employeeTag &&
            client &&
            client.membership_level_id < 4
            " class="text-center py-3 border-bottom bg-white">
            <p class="mb-3">{{ $t("receive_better_matches") }}</p>
            <div>
              <b-button @click="goToDiamondPayment(3)" pill size="sm"
                class="btn getMatchBoostBtn text-white px-3 text-uppercase">{{ $t("get_personal_matchmaker") }}</b-button>
            </div>
          </div>

          <div id="messageListDiv" v-if="!showTargetProfile && conversations.length > 0">
            <div v-for="msg in conversations" :key="msg.client_msg_id">
              <p v-if="msg.showTime" class="small text-7e fw-300 text-center mt-3 mb-1">
                {{ msg.prettyTime }}
              </p>
              <!-- <div
                v-if="
                  msg.msg_type == 2 &&
                  msg.from_client_id == targetClient.client_id
                "
                class="text-center my-3"
              >
                <div
                  v-if="msg.type == 1"
                  class="p-2 font-weight-normal text-muted"
                  :style="chatLineColor(msg.extras)"
                  v-html="htmlFormat(msg.msg, msg.extras)"
                >
            
                </div>
                <div v-if="msg.extras" class="text-center mb-2"></div>
              </div> -->
              <!-- (msg.from_client_id == targetClient.client_id ||
                    targetClientId == employeeTag) && -->
              <div v-if="msg.msg_type != 2 && msg.to_client_id == userInfo.client_id
                ">
                <div class="d-flex justify-content-star mb-1 ml-3">
                  <!-- <div
                    v-if="
                      msg.from_client_id == targetClient.client_id ||
                      targetClientId == employeeTag ||
                      targetClientId == matchmakerChatId
                    "
                    class="targetImg"
                  >
                    <b-img class="w-100" :src="targetClient.photo_url"></b-img>
                  </div> -->
                  <div class="chat-row">
                    <p v-if="targetClientId == employeeTag ||
                      targetClientId == matchmakerChatId
                      " class="text-7e fs-12" v-html="showMathmakerName(msg.extras)"></p>
                    <div v-if="msg.type == 1" class="p-2 chat-content" :style="chatLineColor(msg.extras)"
                      v-html="htmlFormat(msg.msg, msg.extras)">
                      <!-- {{ msg.msg | htmlFormat }} " -->
                    </div>
                    <div @click="chatClick(msg.extras)" v-if="msg.type == 2" class="chat-content">
                      <b-img-lazy blank-color="#777" :blank="true" width="200" :src="msg.photo_url"></b-img-lazy>
                    </div>
                  </div>
                </div>
              </div>
              <!-- &&
                  (msg.to_client_id == targetClient.client_id ||
                    targetClientId == employeeTag) -->
              <div v-if="msg.msg_type != 2 && msg.from_client_id == userInfo.client_id
                " class="d-flex ls-justify-content-end mb-1 mr-3">
                <div class="chat-row">
                  <div v-if="msg.type == 1" class="p-2 chat-content me-chat" :style="chatLineColor(msg.extras)"
                    v-html="htmlFormat(msg.msg, msg.extras)">
                    <!-- {{ msg.msg | htmlFormat }} -->
                  </div>
                  <div @click="chatClick(msg.extras)" v-if="msg.type == 2" class="chat-content me-chat">
                    <b-img-lazy blank-color="#777" :blank="true" width="200" :src="msg.photo_url"></b-img-lazy>
                  </div>
                </div>
              </div>
            </div>
            <div id="msgBottom"></div>
          </div>

          <!-- <div v-if="seeMoreMessage" class="text-center">
            <a href="javascript:;" @click="getMoreTIMMessageList"
              >View More Messages</a
            >
          </div> -->

          <div id="timMessageListDiv" style="min-height: 300px" v-if="TIMMessageList.length > 0">
            <div v-for="msg in TIMMessageList" :key="msg.ID">
              <!-- <div
                v-if="
                  msg.msg_type == 2 &&
                  msg.from_client_id == targetClient.client_id
                "
                class="text-center my-3"
              >
                <div
                  v-if="msg.type == 1"
                  class="p-2 font-weight-normal text-muted"
                  :style="chatLineColor(msg.extras)"
                  v-html="htmlFormat(msg.msg, msg.extras)"
                >
                  
                </div>
                <div v-if="msg.extras" class="text-center mb-2">
                  <a
                    v-if="msg.extras.id == 'date_feedback_for_you'"
                    href="javascript:;"
                    @click="viewFeedback"
                    class="font-weight-bold"
                    >{{ $t("view_feedback_txt") }}</a
                  >
                </div>
                <p class="small text-muted font-weight-light">
                  {{ msg.sent_time | timestamp }}
                </p>
              </div> -->
              <p v-if="msg.showTime" class="small text-7e fw-300 text-center mt-3 mb-1">
                {{ msg.prettyTime }}
              </p>
              <div v-if="client && msg.from != client.tencent_im_userID" class="d-flex justify-content-star mb-1">
                <div class="targetImg">
                  <b-img v-if="targetClientId == employeeTag ||
                    targetClientId == matchmakerChatId
                    " class="w-100" :src="myEmployee.photo_url"></b-img>
                  <b-img v-else class="w-100" :src="msg.avatar"></b-img>
                </div>
                <div class="chat-row">
                  <div v-if="msg.type == 'TIMTextElem'" class="p-2 chat-content"
                    :style="chatLineColor(msg.cloudCustomData)"
                    v-html="htmlFormat(msg.payload.text, msg.cloudCustomData)">
                    <!-- {{ msg.msg | htmlFormat }} @click="imgClick(msg.extras)"-->
                  </div>
                  <div @click="chatRowClick(msg)" v-if="msg.type == 'TIMImageElem'" class="chat-content">
                    <b-img-lazy blank-color="#777" :blank="true" width="200"
                      :src="msg.payload.imageInfoArray[1]['url']"></b-img-lazy>
                  </div>
                  <!-- <p class="small text-muted font-weight-light text-right">
                    {{ msg.time | timestamp }}
                  </p> -->
                </div>
              </div>

              <div v-if="client && msg.from == client.tencent_im_userID" class="d-flex ls-justify-content-end mb-1 mr-3">
                <div class="chat-row">
                  <div v-if="msg.type == 'TIMTextElem'" class="p-2 chat-content me-chat"
                    :style="chatLineColor(msg.cloudCustomData)"
                    v-html="htmlFormat(msg.payload.text, msg.cloudCustomData)">
                    <!-- {{ msg.msg | htmlFormat }} @click="imgClick(msg.extras)"-->
                  </div>
                  <div v-if="msg.type == 'TIMImageElem'" class="chat-content me-chat">
                    <b-img-lazy blank-color="#777" :blank="true" width="200"
                      :src="msg.payload.imageInfoArray[1]['url']"></b-img-lazy>
                  </div>
                  <!-- <p class="small text-muted font-weight-light text-right">
                    {{ msg.time | timestamp }}
                  </p> -->
                </div>
              </div>
            </div>
            <div id="msgBottom2"></div>
          </div>

          <div>
            <p v-if="loadingConversation" class="text-center mt-5 text-muted">
              {{ $t("Loading") }}...
            </p>
          </div>

          <div v-if="isLoading" class="text-center">
            <b-spinner label="Spinning"></b-spinner>
          </div>
        </div>

        <!--content foot-->
        <div v-if="canChat" class="chat-input-box">
          <!-- <div class="text-center" v-html="showHtmlLable(labelArray)"></div> -->

          <div class="d-flex justify-content-between">
            <!-- <div>
              <a href="javascript:;" @click="toggleEmoji" class="btn px-2"
                >&#128512;</a
              >
            </div> -->
            <div class="bg-white" style="width: calc(100% - 48px)">
              <!-- <div class="msgInput" contenteditable="true"><br /></div> -->
              <msgInput ref="msgInput" @change="msgInputChange"></msgInput>
            </div>
            <div class="d-flex align-items-end ml-2 text-right" style="min-width: 40px">
              <b-button @click="sendMsg" pill variant="ee1" :disabled="isSending || message.length == 0 || isChatConfirmed
                ? true
                : false
                " class="border-0 o-btn m38" style="padding: 0; line-height: 0"><i
                  class="fa-light fa-paper-plane-top fs-20" :class="isSending || message.length == 0 || isChatConfirmed
                    ? 'text-faf'
                    : 'text-white'
                    " style="position: relative; left: 2px"></i>
                <!-- <i
                  class="lsicon lsicon-38"
                  :class="
                    isSending || message.length == 0 || isChatConfirmed
                      ? 'chat_send_grey'
                      : 'chat_send_gold'
                  "
                ></i> -->
              </b-button>
            </div>
          </div>
          <!-- <div v-if="chatCloseLeftSec > 0" class="chatCloseText mt-3">
            <span class="text-danger font-weight-bold">{{
              chatCloseText
            }}</span>
          </div> -->
          <!-- <div v-if="showEmoji" class="pt-2">
            <span
              style="cursor: pointer; margin: 5px"
              v-for="emo in emojis"
              :key="emo"
              v-html="emo"
              @click="addEmo"
            ></span>
          </div> -->
        </div>
        <div v-else class="text-danger text-center pt-2 position-relative" style="height: 57px">
          <div class="bg-ee1 px-3 py-5 text-white" style="position: absolute; bottom: 15px; width: 100%">
            <div v-if="clientExpired == 1">
              <p class="fw-600" v-html="$t('y_member_expired').replace('text-ls-red3', '')"></p>
              <p class="mb-3">
                {{
                  $t("renew_chat_with").replace(
                    "[match_first_name]",
                    targetClient.first_name
                  )
                }}
              </p>
              <b-button @click="gotoRenew" variant="1d" pill class="px-5 l-space2 text-uppercase" style="height: 53px">
                {{ $t("renew_now") }}</b-button>
            </div>
            <div v-else>
              <div class="mb-3">
                <i class="fa-regular fa-door-open fs-40"></i>
              </div>
              <div data-id="6676" v-html="$t('match_ended_service')"></div>
              <!-- <div class="fw-500" data-id="6707">{{ $t("match_no_available") }}</div> -->
            </div>
            <!-- <div v-if="!clientExpired && targetClientExpired == 1">
              <div data-id="6676" v-html="$t('match_ended_service')"></div>
            </div>
            <p v-if="!clientExpired && !targetClientExpired">
              {{ $t("permission_limit_view") }}
            </p> -->
          </div>
        </div>
      </div>
    </div>
    <div v-if="!hasContacts && !isLoadingContact" class="layer-fixed bg-faf">
      <div class="center_center text-center text-1d">
        <p class="fs-20 fw-600 mb-5 text-ee1">Chat with matches here</p>
        <p class="mb-5 text-7e">
          Messages from your matches will show up here. Start by liking your
          matches!
        </p>
        <b-button @click="goToSearchPage" pill variant="ee1" class="px-5 fw-600">Start liking</b-button>
      </div>
    </div>

    <scheduleConsultation ref="scheduleConsultation" />

    <startDatePopupCom ref="startDatePopupCom" @afterSendGreetingMessage="afterSendGreetingMessageHandler" />
    <!-- <membershipUpgradePopup ref="membershipUpgradePopup" /> -->
    <!-- <inviteConfirmPopup ref="inviteConfirmPopup" /> -->
    <blockReportUserPopupVue ref="blockReportUserPopupVue" @afterBlock="afterBlockHandle" />
    <!-- <testPopup ref="testPopup"/> -->
  </div>
</template>
<script>
import io from "socket.io-client";
import topName from "../common/topNav";
import helper from "../../utils/helper";
// import Emojis from "../../assets/js/emoji.js";
import scheduleConsultation from "../common/scheduleConsultation";

import startDatePopupCom from "../common/startDatePopupCom.vue";

import { tencentIM } from "../../utils/mixin.js";
import blockReportUserPopupVue from "../common/blockReportUserPopup.vue";
import msgInput from "../common/msgInput";
export default {
  name: "chat-index",
  components: {
    topName,
    msgInput,
    scheduleConsultation,
    startDatePopupCom,
    blockReportUserPopupVue,
  },
  mixins: [tencentIM],
  data() {
    return {
      tim: null,
      skeletonTotal: 12,
      client: null,
      clientExpired: 0,
      clientDateCredit: 0,
      clientEventTicket: 0,
      showBackBtn: true,
      chatTextBoxRow: 1,
      isLoading: false,
      isLoadingContact: true,
      contacts: [],
      matchedContacts: [],
      skippedContacts: [],
      page: 1,
      isEnd: false,
      hasContacts: false,
      conversations: [],
      employeeContacts: [],
      showLeftPlan: true,
      showRightPlan: true,
      showTopNav: true,
      targetClientId: 0,
      targetClientEnId: "",
      message: "",
      targetClient: null,
      targetClientExpired: 0,
      userInfo: this.$storage.get("userInfo"),
      listContentClass: "",
      listContentStyle: null,
      contentBoxStyle: null,
      socket: null,
      isSending: false,
      myEmployee: null,
      groupLastMsg: {
        type: 1,
        msg: "",
        sent_time: 0,
        unread_count: 0,
      },
      employeeTag: "employee",
      loadingConversation: false,
      showEmoji: false,
      //   emojis: Emojis,
      contactType: 1,
      canChat: true,
      canotChatReason: "This chat line has been blocked",
      chatCloseLeftSec: 0,
      firstLabelText: "",
      labelArray: [],
      labelText: "",
      labelText2: "",
      labelColor: "",
      dateTitle: null,
      targetIntro: null,
      buttons: {
        skip: 0,
        date: 0,
        confirm: 0,
        change: 0,
        submit_feedback: 0,
        view_feedback: 0,
        go_verify: 0,
      },
      dateHim: "",
      dateHer: "",
      dateHimAgain: "",
      dateHerAgain: "",
      secLeft: 0,
      countdownLeftTimer: null,
      countdownLeftTimer2: null,
      refPage: "",
      chatCloseText: "",
      chatCloseDesc: "",
      dateStartIndex: [],
      dateStartTimer: null,
      listQuery: {
        page: 1,
        page_size: 30,
        search_text: "",
        // test: 1,
      },
      listItemLen: 0,
      targetProfile: null,
      dateCompletedText: "",
      dateExpiredText: "",
      dateStartedFor: "",
      dateStartIn: "",
      showTargetProfile: false,
      fullViewLabel: false,
      currentDateTimeString: "",
      currentDateVenue: "",
      isNoDates: false,
      isChatExpried: 0,
      isChatConfirmed: 0,
      dateTimeHtml: "",
      matchTimeHtml: "",
      imMode: "tim",
      loopTimer: null,
      callLoopTimerNum: 1,
      loadConversationListNuM: 0,
      TIMMessageList: [],
      currentTIMConversationID: null,
      TIMNextReqMessageID: null,
      TIMMessageListIsCompleted: false,
      seeMoreMessage: true,
      totalUnreadMsg: 0,
      chatMatchmaker: {
        client_id: 0,
        first_name: "配对顾问", //self.myEmployee.first_name,
        photo_url:
          "https://cdn-cf2.lovestruck.com/upload-s/gen/img/lsm-app/LSM_icon_large.png", //self.myEmployee.photo_url, //_.get(item, "userProfile.avatar"),
      },
      matchmakerChatId: "e_449",
      messagePage: 1,
      messagePageSize: 50,
      isTIMSdkReady: false,
      startX: 0, // 初始位置
      endX: 0, // 结束位置
      swipeStyle: "", // 滑动的样式
      wd: 200, // 编辑和删除按钮的宽度之和
      swipeWd: 0, // 已经滑动的距离
      activeId: "", // 实际是上一次的活动id
      waitingBlockUser: null,
      rowStyle: null,
      showReportBlockBtn: false,
      lastChatSentTime: 0,
      //   isMatchmakingChat: false,
    };
  },
  computed: {},
  mounted() {
    let self = this;
    let planNotNow = self.$storage.get("plan-no-now");
    self.$parent.closeLoading();
    // self.$parent.loadBranchBar();
    // console.log("token=", _.get(self.$storage.get("user"), "token"));

    let clientId = _.get(self.$route, "query.clientId", 0);
    let view = _.get(self.$route, "query.view", 0);
    let ref = _.get(self.$route, "query.ref", "");
    // console.log(clientId, self.employeeTag);

    clientId = self.parseId(clientId);
    console.log("parse id=", clientId);

    if (!clientId && self.$route.name == "matchmaker-chat") {
      clientId = self.employeeTag;
      self.targetClient = self.myEmployee;
    }
    self.targetClientId = clientId;
    // self.targetClientEnId = clientId;
    self.matchmakerChatId = _.get(
      self.$storage.get("userInfo"),
      "matchmaker_chat_id"
    );
    console.log("self.matchmakerChatId", self.matchmakerChatId);

    // if (self.matchmakerChatId == clientId) {
    //   self.isMatchmakingChat = true;
    // } else {
    //   self.isMatchmakingChat = false;
    // }

    self.myEmployee = {
      client_id: 0,
      first_name: _.get(
        self.$storage.get("userInfo"),
        "chat_matchamker_info.name"
      ),
      photo_url: _.get(
        self.$storage.get("userInfo"),
        "chat_matchamker_info.photo_url"
      ),
    };

    if (self.imMode == "tim") {
      self.tencentImUserID = _.get(
        self.$storage.get("userInfo"),
        "tencent_im_userID"
      );
      self.tencentImSig = _.get(
        self.$storage.get("userInfo"),
        "tencent_im_sig"
      );
      self.initTIM();
    }

    console.log("tag", clientId, self.targetClientId, self.employeeTag);
    self.getContacts();
    self.$api
      .getClientMe(self.$store.state)
      .then((rs) => {
        self.client = rs.client;

        if (self.client.membership_level_id < 1) {
          self.$router.push({ name: "free-index" });
          return false;
        }

        self.clientDateCredit =
          _.get(self.client, "date_credits", 0) > 0
            ? parseInt(_.get(self.client, "date_credits", 0))
            : 0;

        self.clientEventTicket =
          _.get(self.client, "event_tickets", 0) > 0
            ? parseInt(_.get(self.client, "event_tickets", 0))
            : 0;

        self.myEmployee = {
          client_id: 0,
          first_name: _.get(self.client, "chat_matchamker_info.name"),
          photo_url: _.get(self.client, "chat_matchamker_info.photo_url"),
        };
        if (ref == "reg") {
          //   self.alertWelcome();
        }
        if (clientId == self.employeeTag || clientId == self.matchmakerChatId) {
          self.targetClient = self.myEmployee;
        }

        if (clientId == self.matchmakerChatId) {
          //   self.targetClient = self.chatMatchmaker;
          self.targetClient.client_id = self.matchmakerChatId;

          //   self.chatMatchmaker.client_id = self.matchmakerChatId;
        }
        if (self.imMode == "tim") {
          self.setCurrentTIMConversationID();

          if (self.targetClientId > 0) {
            console.log("self.getChatTarget...");
            self.getChatTarget();
          }
        } else {
          self.getContacts();
        }
        //self.imMode == "websocket" &&
        if (clientId == self.employeeTag || clientId == self.matchmakerChatId) {
          //   if (self.client.membership_level_id < 2) {
          //     self.getGoldPayment(2);
          //     return false;
          //   } else {
          self.canChat = true;
          self.getClientConversations(0, true);
          //   }
          //   console.log("getClientConversations for employee");
        }
        if (self.imMode == "websocket" && clientId > 0) {
          self.addContact(clientId);
          self.getClientConversations(clientId, false);
          //   if (view == 1) {
          //     self.showTargetPrifile(clientId);
          //   }
        }
        // }
        setTimeout(function () {
          self.bindChatLinkEvent();
        }, 800);
      })
      .catch((err) => {
        self.$layer.closeAll("loading");
      });

    // self.getContacts();
    // if (self.$route.name == "client-chat") {
    //   self.getMatchContacts();
    // }

    self.targetProfile = null;

    //self.$route.query

    if (ref == "reg") {
      //   self.alertWelcome();
    } else {
      self.$storage.set("chat-ref", ref);
    }

    self.refPage = self.$route.name;
    console.log("mounted");
    self.displayPlan();
    setTimeout(function () {
      //   console.log("resizeWin-0");
      self.resizeWin();
    }, 80);

    setTimeout(function () {
      //   self.alertWelcome();
      //   self.sendFeedback();
      $(".chat-row .chat-content img").css({ "min-height": "auto" });
    }, 5000);

    $(window).on("resize", function () {
      //   console.log("win resize");
      //   console.log("resizeWin-1");
      self.resizeWin();
    });
    $(window).on("zoom", function () {
      console.log("win zoom");
    });
  },
  methods: {
    msgInputChange(text) {
      this.message = text;
    },
    parseId(id) {
      let self = this;
      // console.log('parseId', id, self.employeeTag, self.matchmakerChatId)
      if (id == self.employeeTag || id == self.matchmakerChatId) {
        return id;
      } else if (/^[\d]+$/.test(id)) {
        return id;
      } else {
        return helper.lshash.decode(id);
      }
    },
    gotoRenew() {
      this.$router.push({ name: "renew-payment" });
    },
    afterBlockHandle(data) {
      let self = this;
      // console.log('afterBlockHandle', self.waitingBlockUser)
      // self.waitingBlockUser

      let index = _.findIndex(self.contacts, {
        target_client_id: _.get(self.waitingBlockUser, "target_client_id"),
      });
      // console.log('afterBlockHandle', self.waitingBlockUser, index)
      if (index > -1) {
        // _.pullAt(self.contacts, [index]);
        self.contacts[index]["can_chat"] = false;
      }

      if (self.targetClientId == self.activeId) {
        //   self.canChat = true;
        self.targetClientId = null;
        self.checkContacts();
      }

      self.swipeStyle = "transform:translateX(0px)";
      self.swipeWd = 0;
      self.activeId = "";

      self.waitingBlockUser = null;
    },
     blockUser(item) {
       this.waitingBlockUser = item;
       //   console.log(item)
       // this.$refs.testPopup.showModal();
       this.$refs.blockReportUserPopupVue.showBlockOnly({
         en_id: _.get(item, "target.en_id"),
         first_name: _.get(item, "target.first_name"),
       });
     },
     reportUser(item) {
       //   console.log(item)
       // this.$refs.testPopup.showModal();
       this.$refs.blockReportUserPopupVue.showReportOnly({
         en_id: _.get(item, "target.en_id"),
         first_name: _.get(item, "target.first_name"),
       });
     },
     getClientX() {
       let e = event || window.event;
       return e.touches[0].clientX;
     },
     getChangedClientX() {
       let e = event || window.event;
       return e.changedTouches[0].clientX;
     },
     touchStart(i) {
       let self = this;
       //console.log('touchStart', i)
       if (i == self.matchmakerChatId) {
         self.swipeStyle = "transform:translateX(0px)";
         self.swipeWd = 0;
         self.activeId = i;
         return false;
       }
       self.startX = self.getClientX();

       self.wd = $(`#${i}-report`).outerWidth() + $(`#${i}-block`).outerWidth();
       // console.log('w=', self.wd)

       if (self.activeId != i) {
         self.swipeStyle = "transform:translateX(0px)";
         self.swipeWd = 0;
       }
       self.activeId = i;
     },
     touchMove() {
       let self = this;
       if (self.activeId == self.matchmakerChatId) {
         return false;
       }
       let moveX = self.getClientX();
       let diff = moveX - self.startX;

       //left
       if (diff < 0 && diff > -self.wd) {
         self.swipeStyle = "transform:translateX(" + diff + "px)";
         self.swipeWd = diff;
       }

       //right
       if (diff > 0 && diff <= self.wd && self.swipeWd < 0) {
         let wx = diff + self.swipeWd;
         self.swipeStyle = "transform:translateX(" + wx + "px)";
       }

       // console.log('touchMove', diff)
     },
     touchEnd() {
       let self = this;
       if (self.activeId == self.matchmakerChatId) {
         return false;
       }
       let endX = self.getChangedClientX();

       let diff = endX - self.startX;

       // console.log('touchEnd', diff, self.activeId, self.swipeWd)
       // console.log('self.swipeWd=', self.swipeWd)

       if (diff < 0) {
         if (diff <= -this.wd / 2) {
           // 向左滑动超过二分之一

           self.swipeStyle = "transform:translateX(" + -this.wd + "px)";

           self.swipeWd = -this.wd;
         } else {
           self.swipeStyle = "transform:translateX(0px)";

           self.swipeWd = 0;
         }
       } else {
         //&& diff >= self.wd / 2
         if (self.swipeWd < 0 && diff > 10) {
           // 向左滑动超过二分之一

           self.swipeStyle = "transform:translateX(0px)";

           self.swipeWd = 0;
         } else {
           self.swipeStyle = "transform:translateX(" + self.swipeWd + "px)";
         }
       }
     },
    gotPayment(type, pageV) {
      this.$router.push({
        name: "contextual-upgrades",
        query: { type: type, page: pageV },
      });
      //   this.$refs.membershipUpgradePopup.showModal({
      //     initialSlideVal: pageV,
      //     membershipType: type,
      //     client: this.client,
      //   });
    },
    bindChatLinkEvent() {
      let self = this;
      $(".chatLink").off("click");

      $(document).on("click", ".chatLink", function () {
        var link = $(this),
          id = link.attr("data-id"),
          t = link.attr("data-t");
        self.extraEvent(id, t);
        // console.log("bindChatLinkEvent", id, t);
      });
    },
    chatRowClick(msg) {
      let self = this;
      //   msg.cloudCustomData;
      //   console.log("chatRowClick", msg, msg.cloudCustomData);
      let cloudCustomData = _.get(msg, "cloudCustomData");
      if (!cloudCustomData) {
        return false;
      }
      let jsonObj = JSON.parse(cloudCustomData);
      let id = _.get(jsonObj, "id");
      let t = _.get(jsonObj, "t");
      self.extraEvent(id, t);
    },
    chatClick(extras) {
      let self = this;
      //   console.log("extras=", extras);
      let id = _.get(extras, "id");
      let t = _.get(extras, "t");
      self.extraEvent(id, t);
    },
    extraEvent(id, t) {
      let self = this;
      switch (t) {
        case "link":
          switch (id) {
            case "schedule_consultation":
              self.$refs.scheduleConsultation.showModal(2);
              break;

            case "upgrade_gold":
              self.getGoldPayment(1);
              break;
            case "upgrade_diamond":
              self.goToDiamondPayment();
              break;
          }

          break;
        case "match-list":
          self.$router.push({ name: "matchmaking-match-list" });
          break;
        case "profile":
          if (id > 0) {
            self.$router.push({
              name: "profile-view",
              query: {
                from:
                  self.targetClientId == self.employeeTag
                    ? "matchamker"
                    : "chat",
              },
              params: { clientId: helper.lshash.encode(id) },
            });
          }
          break;
      }
    },
    getChatTarget() {
      let self = this;
      //   console.log("getChatTarget");
      self.$api
        .getChatTarget(
          helper.lshash.encode(self.targetClientId),
          self.$store.state
        )
        .then((rs) => {
          self.canChat = _.get(rs, "can_chat", true);
          self.targetClient = _.get(rs, "target_client");
          //   self.targetClientId = _.get(rs, "target_client.client_id");

          if (!self.canChat && _.get(rs, "canot_chat_reason")) {
            self.canotChatReason = _.get(rs, "canot_chat_reason");
          }

          //   console.log("self.targetClient", self.targetClient);
          //   _.get(rs, 'client_intro')

          self.checkTargetClientExpired();
          self.checkClientExpired();
          self.dateTimeHtml = self.showHtmlLable(
            _.get(rs, "client_intro.date_time_array")
          );
          self.matchTimeHtml = self.showHtmlLable(
            _.get(rs, "client_intro.match_time_array")
          );
        });
    },
    offMessageListScroll() {
      console.log("unbind scroll");
      $("#contentBody").off("scroll");
    },
    messageListScroll() {
      let self = this;
      //   console.log("bind scroll", $("#messageListDiv").offset().top);
      //getMoreTIMMessageList
      $("#contentBody").on("scroll", function () {
        // return false;
        // if (!$("#timMessageListDiv") || $("#timMessageListDiv").length == 0) {
        //   $("#contentBody").off("scroll");
        //   return false;
        // }
        let scrollTop = 0;
        if ($("#timMessageListDiv").length > 0) {
          scrollTop = $("#timMessageListDiv").offset().top;
        } else if ($("#messageListDiv").length > 0) {
          scrollTop = $("#messageListDiv").offset().top;
        }

        // console.log("timMessageList", scrollTop);
        if (
          !self.loadingConversation &&
          scrollTop > 100 &&
          self.seeMoreMessage
        ) {
          console.log("more message");
          //   self.getMoreTIMMessageList();
          if (
            self.targetClientId == self.employeeTag ||
            self.targetClientId == self.matchmakerChatId
          ) {
            self.getClientConversations(0, 1);
          } else {
            self.getClientConversations(self.targetClientId, 0);
          }
        }
      });
    },
    useTIM() {
      let self = this;
    },
    onTIMMessageReceived(event) {
      let self = this;
      //   console.log(
      //     "onMessageReceived",
      //     self.currentTIMConversationID,
      //     event.data
      //   );
      if (!event.data) {
        return false;
      }
      console.log(
        "onMessageReceived",
        self.currentTIMConversationID,
        event.data
      );
      for (var msg of event.data) {
        if (msg.conversationID == self.currentTIMConversationID) {
          let msgArr = self.setTimeShow([msg]);
          //   console.log("msgArr", msg, msgArr);
          self.TIMMessageList = [...self.TIMMessageList, ...msgArr];
          self.scrollToBottom();
          //   if (msg.from == self.getClientUserID(self.targetClientId)) {

          //   } else {
          //     // let targetId =
          //   }

          //   console.log("self.TIMMessageList", self.TIMMessageList);
          self.setTIMMessageRead();
        }
        // else {
        let clientId = "";
        if (msg.conversationID.indexOf(self.matchmakerChatId) > -1) {
          clientId = self.matchmakerChatId;
        } else {
          clientId = parseInt(msg.conversationID.replace("C2Cc_", ""));
        }
        //   //target_client_id
        //   /**
        //      * sent_time: _.get(item, "lastMessage.lastTime"),
        //           unread_count: _.get(item, "unreadCount", 0),
        //           type: type,
        //           msg: msg,
        //           target_client_id: client_id,
        //           target: {
        //             client_id: client_id,
        //             first_name: first_name,
        //             photo_url: _.get(item, "userProfile.avatar"),
        //           },
        //      */
        let index = _.findIndex(self.contacts, {
          target_client_id: clientId,
        });
        if (index > -1) {
          self.contacts[index]["sent_time"] = _.get(msg, "time");
          if (_.get(msg, "type") == "TIMImageElem") {
            self.contacts[index]["msg"] = "PHOTO";
          } else {
            //TIMTextElem
            self.contacts[index]["msg"] = _.get(msg, "payload.text", "");
          } //
          if (msg.conversationID != self.currentTIMConversationID) {
            self.contacts[index]["unread_count"] += 1;
          }
        } else {
          let type = 1;
          let msgTxt = "";
          if (_.get(msg, "type") == "TIMTextElem") {
            type = 1;
            msgTxt = _.get(msg, "payload.text");
            //
          } else if (_.get(msg, "type") == "TIMImageElem") {
            type = 2;
            msgTxt = "PHOTO";
          }

          //     // let targetId = '';
          //     // if(msg.conversationID.indexOf('C2Ce_') > -1){
          //     //     targetId = msg.conversationID.replace('C2C', '');
          //     // }else{

          //     // }

          self.contacts.unshift({
            sent_time: _.get(msg, "time"),
            unread_count: 1,
            type: type,
            msg: msgTxt,
            target_client_id: clientId,
            target: {
              client_id: clientId,
              first_name:
                clientId == self.matchmakerChatId
                  ? self.myEmployee.first_name
                  : _.get(msg, "nick"),
              photo_url:
                clientId == self.matchmakerChatId
                  ? self.myEmployee.photo_url
                  : _.get(msg, "avatar"),
            },
          });
        }
      }
      // else if(msg.to == self.client.tencent_im_userID){
      //     let client_id = msg.from.replace("c_", "");
      //       let c_index = _.get(self.contacts, {
      //         target_client_id: client_id,
      //       });
      // }
      //   }
      //   self.getTIMConversationList();
    },
    onTIMSdkReady() {
      let self = this;
      self.isTIMSdkReady = true;
      console.log("onTIMSdkReady", 0);
      //   self.getTIMConversationList();

      //   self.loopTIMConversationList(2000);
      if (self.targetClientId > 0) {
        self.getTIMMessageList();
        // self.getClientConversations(self.targetClientId, 0);
      } else if (self.targetClientId == self.employeeTag) {
        // self.canChat = false;
        // self.$api.getApplyCreateTIMGroup("", self.$store.state).then((rs) => {
        //   //   console.log("getApplyCreateTIMGroup...", rs);
        //   if (_.get(rs, "success", false)) {
        //     self.client.tencent_im_groupID = _.get(rs, "group_info.GroupId");
        //     // self.getClientConversations(0, 1);
        //     self.getTIMMessageList();
        //     self.canChat = true;
        //   } else if (_.get(rs, "need_upgrade", 0) == 1) {
        //     console.log("group check res", rs.message);
        //     self.canChat = false;
        //     self.getClientConversations(0, true);
        //   }
        // });
      }
      //   else if(self.targetClientId == self.matchmakerChatId){
      //     //   self.getClientConversations(0, 1);
      //       self.getTIMMessageList();
      //       self.canChat = true;
      //   }
    },
    onSdkNotReady() {
      console.log("onSdkNotReady");
      this.TIMLogin();
    },

    // loopTIMConversationList(speed = 10000) {
    //   let self = this;
    //   if (self.loopTimer) {
    //     clearInterval(self.loopTimer);
    //   }
    //   //   console.log(
    //   //     "self.callLoopTimerNum * speed = ",
    //   //     self.callLoopTimerNum * speed
    //   //   );
    //   self.loopTimer = setInterval(function () {
    //     self.getTIMConversationList();
    //   }, self.callLoopTimerNum * speed);
    //   //   self.getTIMConversationList();

    //   self.callLoopTimerNum++;
    // },
    getTIMConversationList() {
      //   console.log("getTIMConversationList...");
      let self = this;
      //   if (self.loadConversationListNuM == 0) {
      self.isLoadingContact = true;
      //   }
      let totalUnread = 0;
      let promise = self.tim.getConversationList();
      //   self.loadConversationListNuM++;
      promise
        .then(function (imResponse) {
          let conversationList = _.get(
            imResponse,
            "data.conversationList",
            null
          ); // 全量的会话列表，用该列表覆盖原有的会话列表
          //   console.log("conversationList=", conversationList);
          //   conversationList = null;
          //   console.log(
          //     "conversationList=",
          //     //     "self.loadConversationListNuM % 60 = ",
          //     //     self.loadConversationListNuM % 60,
          //     conversationList
          //   );
          if (!conversationList) {
            console.log("no conversationList");
            self.contacts = self.storageConversationList("get");
            return false;
          }

          self.isLoadingContact = false;
          //   if (!conversationList && self.loadConversationListNuM % 60 == 0) {
          //     console.log("ssssssssssssssssssss", "self.loopTimer");
          //     // clearInterval(self.loopTimer);
          //     self.loopTIMConversationList(5000);
          //     return false;
          //   }
          //   self.localContacts = self.storageConversationList("get");
          self.contacts = [];
          self.employeeContacts = [];

          for (var item of conversationList) {
            // console.log("for conversationList", item);
            let type = 1;
            let msg = "";
            if (_.get(item, "lastMessage.type") == "TIMTextElem") {
              type = 1;
              msg = _.get(item, "lastMessage.payload.text");
              //
            } else if (_.get(item, "lastMessage.type") == "TIMImageElem") {
              type = 2;
              msg = "PHOTO";
            }

            totalUnread += _.get(item, "unreadCount", 0);

            //for c2c
            if (item.conversationID.indexOf("C2Cc_") > -1) {
              let client_id = parseInt(
                item.conversationID.replace("C2Cc_", "")
              );

              //   let emp_id = parseInt(
              //     item.conversationID.replace("C2Ce_", "")
              //   );
              // let c_index = _.findIndex(self.localContacts, {
              //   target_client_id: client_id,
              // });
              let first_name = _.get(item, "userProfile.nick", "");
              let text = $.trim(self.listQuery.search_text);
              //search
              if (
                !text ||
                (text &&
                  (first_name.indexOf(text) > -1 || msg.indexOf(text) > -1))
              ) {
                self.contacts.push({
                  sent_time: _.get(item, "lastMessage.lastTime"),
                  unread_count: _.get(item, "unreadCount", 0),
                  type: type,
                  msg: msg,
                  target_client_id: client_id,
                  target: {
                    client_id: client_id,
                    first_name: first_name,
                    photo_url: _.get(item, "userProfile.avatar"),
                  },
                });
              }
            } else if (item.conversationID.indexOf("GROUPM2CG_") > -1) {
              self.contacts.push({
                sent_time: _.get(item, "lastMessage.lastTime"),
                unread_count: _.get(item, "unreadCount", 0),
                type: type,
                msg: msg,
                target_client_id: self.employeeTag,
                target: {
                  client_id: self.employeeTag,
                  first_name: self.myEmployee.first_name,
                  photo_url: self.myEmployee.photo_url, //_.get(item, "userProfile.avatar"),
                },
              });
            }
            //TODO for group
            //item.conversationID.indexOf("GROUPM2CG_") > -1
            else if (item.conversationID.indexOf(self.matchmakerChatId) > -1) {
              //   let client_id = parseInt(
              //     item.conversationID.replace("GROUPM2CG_", "")
              //   );
              //   console.log("include", item);
              //"https://cdn-cf2.lovestruck.com/upload-s/gen/img/red_design_2021/superlike_diamond_150x150.png",
              self.contacts.push({
                sent_time: _.get(item, "lastMessage.lastTime"),
                unread_count: _.get(item, "unreadCount", 0),
                type: type,
                msg: msg,
                target_client_id: self.matchmakerChatId,
                target: {
                  client_id: self.matchmakerChatId,
                  first_name: self.myEmployee.first_name,
                  photo_url: self.myEmployee.photo_url, //_.get(item, "userProfile.avatar"),
                },
              });

              //   self.groupLastMsg = {
              //     sent_time: _.get(item, "lastMessage.lastTime"),
              //     unread_count: _.get(item, "unreadCount", 0),
              //     type: type,
              //     msg: msg,
              //   };

              //   self.employeeContacts.push({
              //     sent_time: _.get(item, "lastMessage.lastTime"),
              //     unread_count: _.get(item, "unreadCount", 0),
              //     type: type,
              //     msg: msg,
              //     client_msg_id: _.get(item, "lastMessage.lastTime"),
              //     employee: {
              //       name: _.get(item, "groupProfile.name"),
              //       photo_url: _.get(item, "groupProfile.avatar"),
              //     },
              //   });
            }

            // console.log("self.contacts=", self.contacts.length);
          }

          self.totalUnreadMsg = totalUnread;
          self.counterMsgUnread();

          if (self.contacts.length > 0) {
            console.log("has contacts", self.contacts.length);
            self.hasContacts = true;
            // self.storageConversationList("set");
            self.contacts = _.sortBy(self.contacts, [
              function (o) {
                return -o.sent_time;
              },
            ]);

            self.checkContacts();
          }
        })
        .catch(function (imError) {
          console.warn("getConversationList error:", imError); // 获取会话列表失败的相关信息
        });
    },
    checkContacts() {
      let self = this;

      if (self.targetClientId) {
        console.log("has targetClientId", self.targetClientId);
        return false;
      }

      //   console.log(
      //     "self.targetClientId",
      //     self.targetClientId,
      //     _.get(self.contacts, "0.target_client_id")
      //   );
      let firstIndex = 0;
      let firstTargetId = _.get(self.contacts, "0.target_client_id");
      if (firstTargetId == "employee") {
        firstIndex = 1;
        firstTargetId = _.get(self.contacts, "1.target_client_id");
      }

      if (firstTargetId && $(window).width() >= 576) {
        setTimeout(function () {
          self.chatToClient(_.get(self.contacts, firstIndex));
        }, 500);
      }
    },
    storageConversationList(type) {
      let self = this;
      let key = `${self.client.client_id}-chat-list`;
      if (type == "set") {
        return self.$storage.set(key, self.contacts);
      } else if (type == "get") {
        return self.$storage.get(key);
      }
    },
    getClientUserID(clientId) {
      return `c_${clientId}`;
    },
    getM2CGroupID(clientId) {
      return `M2CG_${clientId}`;
    },
    getEmployeeUserId() {
      return this.matchmakerChatId;
      // return `e_${this.matchmakerChatId}`;
    },
    getConversationType() {
      let self = this;
      //   return TIM.TYPES.CONV_C2C;
      // if (self.targetClientId > 0) {
      //   return TIM.TYPES.CONV_C2C;
      // } else
      if (self.targetClientId == self.employeeTag) {
        return self.TIMO.TYPES.CONV_GROUP;
      } else {
        return self.TIMO.TYPES.CONV_C2C;
      }
    },
    getToUserID() {
      let self = this;
      if (self.targetClientId > 0) {
        return self.getClientUserID(self.targetClientId);
      } else if (self.targetClientId == self.employeeTag) {
        //
        return self.getM2CGroupID(self.client.client_id);
      } else {
        return self.getEmployeeUserId();
      }
    },
    setCurrentTIMConversationID() {
      let self = this;
      if (self.targetClientId > 0) {
        self.currentTIMConversationID = `C2Cc_${self.targetClientId}`;
      } else if (self.targetClientId == self.employeeTag) {
        self.currentTIMConversationID = `GROUPM2CG_${self.client.client_id}`;
      } else if (self.targetClientId == self.matchmakerChatId) {
        self.currentTIMConversationID = `C2C${this.matchmakerChatId}`;
      }
    },
    getTIMMessageList() {
      let self = this;
      //   console.log("getTIMMessageList...");

      if (
        self.targetClientId == self.employeeTag ||
        self.targetClientId == self.matchmakerChatId
      ) {
        self.getClientConversations(0, 1);
      } else {
        self.getClientConversations(self.targetClientId, 0);
      }
      //2023.05.12
      return false;

      if (!self.isTIMSdkReady) {
        return false;
      }

      self.setCurrentTIMConversationID();

      self.$layer.loading();
      self.loadingConversation = true;
      //   self.canChat = true;
      self.setTIMMessageRead();
      self.TIMMessageList = [];
      self.lastChatSentTime = 0;
      self.tim
        .getMessageList({
          conversationID: self.currentTIMConversationID,
          count: 15,
        })
        .then(function (imResponse) {
          //   console.log("self.TIMMessageList", imResponse.data.messageList);
          let resMsg = self.setTimeShow(imResponse.data.messageList);
          self.TIMMessageList = [...self.TIMMessageList, ...resMsg]; // 消息列表。

          self.TIMNextReqMessageID = imResponse.data.nextReqMessageID; // 用于续拉，分页续拉时需传入该字段。
          self.TIMMessageListIsCompleted = imResponse.data.isCompleted; // 表示是否已经拉完所有消息。
          self.loadingConversation = false;

          self.scrollToBottom();
          self.$layer.closeAll("loading");

          if (self.TIMMessageList.length == 0) {
            if (
              self.targetClientId == self.employeeTag ||
              self.targetClientId == self.matchmakerChatId
            ) {
              self.getClientConversations(0, 1);
            } else {
              self.getClientConversations(self.targetClientId, 0);
            }
          } else {
            self.seeMoreMessage = true;
            setTimeout(function () {
              self.messageListScroll();
              console.log("messageListScroll");
            }, 2000);
          }
        });
    },
    getMoreTIMMessageList() {
      let self = this;
      //load from server

      self.setCurrentTIMConversationID();

      self.setTIMMessageRead();
      self.loadingConversation = true;
      self.tim
        .getMessageList({
          conversationID: self.currentTIMConversationID,
          nextReqMessageID: self.TIMNextReqMessageID,
          count: 15,
        })
        .then(function (imResponse) {
          self.loadingConversation = false;

          //   self.TIMMessageList = [
          //     ...imResponse.data.messageList,
          //     ...self.TIMMessageList,
          //   ]; // 消息列表。

          self.TIMMessageList = self.setTimeShow([
            ...imResponse.data.messageList,
            ...self.TIMMessageList,
          ]);

          self.TIMNextReqMessageID = imResponse.data.nextReqMessageID; // 用于续拉，分页续拉时需传入该字段。
          self.TIMMessageListIsCompleted = imResponse.data.isCompleted; // 表示是否已经拉完所有消息。

          //   self.seeMoreMessage = !self.TIMMessageListIsCompleted;

          if (self.TIMMessageListIsCompleted) {
            if (
              self.targetClientId == self.employeeTag ||
              self.targetClientId == self.matchmakerChatId
            ) {
              self.getClientConversations(0, 1);
            } else {
              self.getClientConversations(self.targetClientId, 0);
            }
            return false;
          }

          self.scrollToBottom();
        });
    },
    setTIMMessageRead() {
      let self = this;

      self.setCurrentTIMConversationID();
      self.tim
        .setMessageRead({
          conversationID: self.currentTIMConversationID,
        })
        .then(function (imResponse) {
          // 已读上报成功，指定 ID 的会话的 unreadCount 属性值被置为0
        })
        .catch(function (imError) {
          // 已读上报失败
          console.warn("setMessageRead error:", imError);
        });
      let pdata = new FormData();
      pdata.append(
        "target_client_id",
        helper.lshash.encode(self.targetClientId)
      );
      //   console.log('postReadConversation', self.targetClientId, helper.lshash.encode(self.targetClientId))
      self.$api.postReadConversation(pdata, self.$store.state);
    },
    sentTIMTextMsg(toUserID, textMsg) {
      let self = this;
      if (!toUserID) {
        toUserID = self.getToUserID();
      }
      //   console.log("toUserID=", toUserID);
      // 发送文本消息，Web 端与小程序端相同
      // 1. 创建消息实例，接口返回的实例可以上屏
      let message = self.tim.createTextMessage({
        to: toUserID,
        conversationType: self.getConversationType(), //TIM.TYPES.CONV_C2C,//TIM.TYPES.CONV_GROUP
        // 消息优先级，用于群聊（v2.4.2起支持）。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息，详细请参考：https://cloud.tencent.com/document/product/269/3663#.E6.B6.88.E6.81.AF.E4.BC.98.E5.85.88.E7.BA.A7.E4.B8.8E.E9.A2.91.E7.8E.87.E6.8E.A7.E5.88.B6)
        // 支持的枚举值：TIM.TYPES.MSG_PRIORITY_HIGH, TIM.TYPES.MSG_PRIORITY_NORMAL（默认）, TIM.TYPES.MSG_PRIORITY_LOW, TIM.TYPES.MSG_PRIORITY_LOWEST
        // priority: TIM.TYPES.MSG_PRIORITY_NORMAL,
        payload: {
          text: textMsg,
        },
        // 消息自定义数据（云端保存，会发送到对端，程序卸载重装后还能拉取到，v2.10.2起支持）
        // cloudCustomData: 'your cloud custom data'
      });
      //   console.log("message", message);
      // 2. 发送消息
      let promise = self.tim.sendMessage(message);
      promise
        .then(function (imResponse) {
          //   self.getTIMConversationList();
          // 发送成功
          //   console.log("send msg", imResponse);
          if (_.get(imResponse, "data.message")) {
            let newMesg = self.setTimeShow([_.get(imResponse, "data.message")]);
            self.TIMMessageList = [...self.TIMMessageList, ...newMesg];
          }
          self.$refs.msgInput.clearText();
          self.message = "";

          self.isSending = false;

          self.scrollToBottom();
        })
        .catch(function (imError) {
          // 发送失败
          console.warn("sendMessage error:", imError);
        });
    },
    counterMsgUnread() {
      let self = this;
      if (self.$refs.topName) {
        self.$refs.topName.setClientUnread("set", self.totalUnreadMsg);
      }
    },
    useWebSocket() {
      let self = this;
      self.socket = io(
        // "http://localhost:3002", {
        "https://im.lovestruck.com",
        {
          //   socket: io("http://localhost:3002", {
          path: "/chat.io",
          query: {
            token: _.get(self.$storage.get("user"), "token"),
          },
        }
      );

      // setTimeout(function () {
      //   self.currentDateTimeString = "明天";
      //   self.currentDateVenue = "家里";
      //   self.confirmDatePopup();
      // }, 3000);

      self.socket.on("message_finish", (data) => {
        console.log("message_finish from employee", data);
      });
      self.socket.on("warn", (data) => {
        console.log("warn from im", data);
      });

      self.socket.on("message", (data) => {
        console.log("get message", data);

        let fromClientId = _.get(data, "fromClientId", 0);
        let modeType = _.get(data, "modeType");
        let msgType = _.get(data, "msgType", 3);
        //   console.log("employee", self.targetClientId, modeType, data);
        if (!fromClientId) {
          let extras = JSON.parse(_.get(data, "extras", null));
          if (_.has(extras, "target_id")) {
            fromClientId = _.get(extras, "target_id", 0);
          }
        }
        //当前用户
        let hasRead = 0;
        if (fromClientId == self.targetClientId || msgType == 2) {
          hasRead = 1;
          self.conversations.push({
            type: _.get(data, "photoUrl") ? 2 : 1,
            msg: unescape(_.get(data, "message")),
            msg_type: msgType == 2 ? 2 : 1,
            to_client_id: _.get(data, "clientId", 0),
            from_client_id: msgType == 2 ? 0 : fromClientId,
            extras: _.get(data, "extras"),
            sent_time: new Date().toString(),
          });
          self.scrollToBottom();
        } //from employee
        else if (self.targetClientId == self.employeeTag && modeType == "e2c") {
          // console.log("sdfs...........d");
          hasRead = 1;
          self.conversations.push({
            type: _.get(data, "photoUrl") ? 2 : 1,
            msg: unescape(_.get(data, "message")),
            to_client_id: self.userInfo.client_id,
            from_client_id: 0,
            extras: _.get(data, "extras"),
            sent_time: new Date().toString(),
          });
          self.scrollToBottom();
        }

        if (modeType == "c2c" || msgType == 2) {
          let i = _.findIndex(self.contacts, {
            target_client_id: fromClientId,
          });
          if (i > -1) {
            let unreadCount = self.contacts[i]["unread_count"];

            self.contacts[i]["unread_count"] = hasRead ? 0 : unreadCount + 1;

            self.contacts[i]["is_read"] = hasRead;
            self.contacts[i]["extras"] = _.get(data, "extras");
            self.contacts[i]["msg"] = _.get(data, "message");
            self.contacts[i]["sent_time"] = parseInt(
              new Date().getTime() / 1000
            );
            self.contacts[i]["type"] = _.get(data, "photoUrl") ? 2 : 1;
            //   if (!hasRead) {
            //     self.setUnread("add");
            //   }

            self.contacts = self.sortBySentTime(self.contacts);
          }
        } else {
          self.employeeContacts[0]["is_read"] = hasRead;
          self.employeeContacts[0]["extras"] = _.get(data, "extras");
          self.employeeContacts[0]["msg"] = _.get(data, "message");
          self.employeeContacts[0]["sent_time"] = parseInt(
            new Date().getTime() / 1000
          );
          self.employeeContacts[0]["type"] = _.get(data, "photoUrl") ? 2 : 1;
        }
      });

      self.socket.on("message_sent", (data) => {
        console.log("message sent!", data);
      });
    },
    goToDiamondPayment() {
      this.gotPayment("diamond", 1);
      //   this.$refs.scheduleConsultation.showModal(2);
      //   this.$router.push({
      //     name: "membership-choose",
      //     query: { type: "diamond" },
      //     // name: "payment-plan",
      //     // params: { planName: "diamond" },
      //     // query: { top_nav: 0, page: pageV },
      //   });
    },
    saveMessageToServer() {
      let self = this;
      if (!self.message && !self.tplMessage) {
        return false;
      }
      //   self.$layer.loading();
      //   console.log("messaeg", self.message);
      let pdata = new FormData();
      //changeDateVenueInvite
      pdata.append("message", self.message ? self.message : self.tplMessage);
      pdata.append("to_client_id", _.get(self.targetClient, "client_id", 0));
      //   pdata.append("is_date_chat", 1);

      self.$api
        .postClientMessage(pdata, self.$store.state)
        .then((rs) => {
          //   console.log("success", rs);
          //   self.$layer.closeAll("loading");
        })
        .catch((error) => {
          self.$layer.closeAll("loading");
          self.$layer.msg(_.get(error, "response.data.error.body"));
          //self.afterAction(intro_id, type);
          console.log(error);
        });
    },
    chatAgain() {
      let self = this;
      if (self.client.membership_level_id <= 2) {
        self.getPlatinumPayment(0);
        return false;
      }

      let introId = _.get(self.targetIntro, "client_intro_id");
      let pdata = new FormData();

      pdata.append("intro_id", introId);
      pdata.append("target_client_id", self.targetClientId);
      pdata.append("act_type", "chat_again");

      self.$layer.loading();
      self.matchAction(introId, pdata, "chat_again");
    },
    showMathmakerName(extras) {
      try {
        if (typeof extras == "string") {
          extras = JSON.parse(extras);
        }
        return _.get(extras, "from_name");
      } catch (err) {
        console.log("err", msg, err);
        return "";
      }
    },
    showHtmlLable(label_array) {
      //   let label_array = _.get(label_button, "label_array");
      //   console.log('')
      let htmlText = "";
      if (label_array) {
        for (let lab of label_array) {
          htmlText += `<span class="${lab.bold == "1" ? "fw-700" : ""
            }" style="color:${lab.color}">${lab.text}</span>`;
        }
        htmlText = htmlText.replace("[icon_chat_expired]", ``);

        return htmlText;
      }
    },
    htmlFormat(msg, extras) {
      let self = this;
      //   console.log("htmlFormat", typeof extras, extras);
      try {
        if (typeof extras == "string") {
          extras = JSON.parse(extras);
        }

        msg = msg.replace(/\r\n/g, "<br/>").replace(/\n/g, "<br/>");

        if (msg.indexOf("[icon_lovestruck]") > -1) {
          msg = msg.replace(
            "[icon_lovestruck]",
            `<img height="28" src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/2021/icon_lovestruck_red.png"/>`
          );
        }

        if (msg.indexOf("[gold_upgrade_action]") > -1) {
          msg = msg.replace(
            "[gold_upgrade_action]",
            `<a href="javascript:;" class="chatLink" data-id="upgrade_gold" data-t="link">${self.$t(
              "button_membership_upgrade"
            )}</a>`
          );
        }

        if (msg.indexOf("[diamond_upgrade_action]") > -1) {
          msg = msg.replace(
            "[diamond_upgrade_action]",
            `<a href="javascript:;" class="chatLink" data-id="upgrade_diamond" data-t="link">${self.$t(
              "button_membership_upgrade"
            )}</a>`
          );
        }

        if (msg.indexOf("<a>") > -1) {
          let id = _.get(extras, "id", "");
          let t = _.get(extras, "t", "");

          let alink = `<a href="javascript:;" class="chatLink" data-id="${id}" data-t="${t}">`;
          msg = msg.replace("<a>", alink);
        }

        return msg;
      } catch (err) {
        // console.log("err", msg, err);
        return msg;
      }
    },
    chatLineColor(extras) {
      let e_color = _.get(extras, "color", "");
      if (e_color) {
        return {
          color: e_color,
        };
      }
      return null;
    },
    goToSearchPage() {
      this.$router.push({
        // name: "search-list",
        name: "matchmaking-match-list",
      });
    },
    noDatesFound() {
      let self = this;
      self.showLeftPlan = false;
      self.showRightPlan = false;
      self.isNoDates = true;
    },
    getVerify() {
      this.$refs.scheduleConsultation.showVerifyAccountModal(1);
    },
    afterSendGreetingMessageHandler(data) {
      let self = this;
      //   console.log("data", data);
      setTimeout(function () {
        self.$refs.startDatePopupCom.closeModel();
        let targetClientId = _.get(data, "to_client_id", 0);
        // let targetClientEnId = _.get(data, "target.en_id", 0);
        if (self.targetClientId == targetClientId) {
          if (self.imMode == "tim") {
            self.getTIMMessageList();
          } else {
            self.getClientConversations(targetClientId, false);
          }
        } else {
          let toId = helper.lshash.encode(
            targetClientId ? targetClientId : self.targetClientId
          );
          self.$router.push({
            name: "client-chat",
            query: {
              clientId: toId,
            },
          });
        }
      }, 500);
    },
    getPlatinumPayment(pageV = 1) {
      this.$router.push({
        // name: "membership-choose",
        name: "membership-upgrade",
        //     query: { type: "gold" },
        // name: "payment-plan",
        // params: { planName: "platinum" },
        // query: { top_nav: 0, page: pageV },
      });
    },
    getGoldPayment(pageV = 1) {
      this.gotPayment("gold", 1);
      //   this.$router.push({
      //     // name: "membership-choose",
      //     name: "membership-upgrade",
      //     query: { type: "gold", page: pageV },
      //     // name: "payment-plan",
      //     // params: { planName: "gold" },
      //     // query: { top_nav: 0, page: pageV },
      //   });
    },
    // //no use
    // alertWelcome() {
    //   let self = this;
    //   let cacheKey = `isshowwel-${self.client.client_id}`;
    //   let isshowwel = self.$storage.get(cacheKey);
    //   if (isshowwel) {
    //     return false;
    //   }

    //   let message = self.$t("welcom_new_user_desc");
    //   // "Welcome to Lovestruck! Please review your new matches and suggest times to go on dates with them!";
    //   self.$bvModal.msgBoxOk(message, {
    //     title: false,
    //     size: "md",
    //     buttonSize: "sm",
    //     hideFooter: true,
    //     // okDisabled: true,
    //     // okTitle: false,
    //     okTitle: self.$t("Ok"),
    //     okVariant: "ls-y",
    //     centered: true,
    //     hideHeaderClose: true,
    //   });

    //   self.$storage.set(cacheKey, 1);
    // },
    chatListScroll() {
      let self = this;
      let listContent = $("#listContent");
      let boxHeight = listContent.height();

      let listItemHeight = 76;
      console.log("chatListScroll");

      $("#listContent").on("scroll", function () {
        // console.log("listContent", $(this).scrollTop());
        let contentHeight = (self.listItemLen + 1) * listItemHeight;

        if (
          !self.isLoadingContact &&
          !self.isEnd &&
          contentHeight - boxHeight <= $(this).scrollTop() + 300
        ) {
          console.log("get more");
          self.getMatchContacts();
        }
      });
    },
    viewProfile() {
      let self = this;
      if (self.targetClientId == self.employeeTag || !_.get(self.targetClient, "en_id") || !_.get(self.targetClient, "client_id")) {
        return false;
      }
      //   if (self.targetProfile) {
      //     return false;
      //   }
      // console.log('targetClient', self.targetClient)
      // return false;
      //profile-view
      self.$router.push({
        name: "profile-view",
        query: { from: "chat" },
        params: { clientId: _.get(self.targetClient, "en_id") },
      });
    },
    // scheduleNowHander() {
    //   this.$refs.diamondPopup.closeModel();
    //   this.$refs.scheduleConsultation.showModal(2);
    // },
    // cancelDate() {
    //   let self = this;
    //   //   console.log("skip date");

    //   self.$bvModal
    //     .msgBoxConfirm(
    //       self
    //         // .$t("cancel_date_desc")
    //         .$t("cancel_date_confirm_desc")
    //         .replace("[match_first_name]", self.targetClient.first_name),
    //       {
    //         // title: self.$t("cancel_date_txt"),
    //         title: false,
    //         headerClass: "text-secondary",
    //         bodyClass: "text-secondary",
    //         size: "md",
    //         buttonSize: "sm",
    //         okVariant: "ls-y",
    //         cancelVariant: "light",
    //         okTitle: self.$t("cancel_txt"),
    //         cancelTitle: self.$t("not_now"),
    //         footerClass: "p-2",
    //         hideHeaderClose: true,
    //         centered: true,
    //       }
    //     )
    //     .then((value) => {
    //       //   console.log("value=", value);
    //       if (value) {
    //         let introId = _.get(self.targetIntro, "client_intro_id");
    //         let fdata = new FormData();
    //         fdata.append("act_type", "cancel");
    //         fdata.append("intro_id", introId);
    //         console.log("cancel intro:", introId);
    //         self.$layer.loading();
    //         self.matchAction(introId, fdata, "");
    //       }
    //     })
    //     .catch((err) => {
    //       // An error occurred
    //       console.log(err);
    //     });
    // },
    // skipDate() {
    //   let self = this;

    //   self.$bvModal
    //     .msgBoxConfirm(self.$t("date_skip_desn"), {
    //       title: `${self.$t("date_skip_title")}`,
    //       headerClass: "text-secondary",
    //       bodyClass: "text-secondary",
    //       size: "md",
    //       buttonSize: "sm",
    //       okVariant: "primary",
    //       okTitle: self.$t("Ok"),
    //       cancelTitle: self.$t("not_now"),
    //       footerClass: "p-2",
    //       hideHeaderClose: true,
    //       centered: true,
    //     })
    //     .then((value) => {
    //       // console.log("value=", value);
    //       if (value) {
    //         //   console.log("skip date");
    //         let introId = _.get(self.targetIntro, "client_intro_id");
    //         let fdata = new FormData();
    //         fdata.append("act_type", "decline");
    //         fdata.append("intro_id", introId);
    //         self.$layer.loading();
    //         self.matchAction(introId, fdata, "");
    //       }
    //     })
    //     .catch((err) => {
    //       // An error occurred
    //       console.log(err);
    //     });
    // },
    // afterSendFeedback(intro_id, data) {
    //   let self = this;
    //   console.log("afterSendFeedback", intro_id, data);
    //   //   let index = _.findIndex(self.pastdates, { client_intro_id: intro_id });
    //   //   let intro = _.get(self.pastdates, index);
    //   //   if (!intro) {
    //   //     return false;
    //   //   }

    //   //   self.pastdates[index]["source_user_feedback_created_time"] = 1;
    //   //   self.pastdates[index]["source_user_enjoy_date"] = _.get(
    //   //     data,
    //   //     "enjoy_date"
    //   //   );
    //   //   self.pastdates[index]["source_user_chemistry_rating"] = _.get(
    //   //     data,
    //   //     "chemistry_rating"
    //   //   );
    //   //   self.pastdates[index]["source_user_date_again"] = _.get(
    //   //     data,
    //   //     "date_again"
    //   //   );
    // },
    // sendFeedback() {
    //   let self = this;
    //   //   let index = _.findIndex(self.pastdates, { client_intro_id: intro_id });
    //   //   let intro = _.get(self.pastdates, index);
    //   //   if (!intro) {
    //   //     return false;
    //   //   }
    //   self.targetIntro.first_name = _.get(self.targetClient, "first_name");
    //   self.targetIntro.photo_url = _.get(self.targetClient, "photo_url");
    //   self.$refs.feedbackModal2.showModal(self.targetIntro, "send");
    // },
    // viewFeedback() {
    //   let self = this;
    //   //   let index = _.findIndex(self.pastdates, { client_intro_id: intro_id });
    //   //   let intro = _.get(self.pastdates, index);
    //   //   if (!intro) {
    //   //     return false;
    //   //   }
    //   self.targetIntro.first_name = _.get(self.targetClient, "first_name");
    //   self.targetIntro.photo_url = _.get(self.targetClient, "photo_url");
    //   self.$refs.feedbackModal2.showModal(self.targetIntro, "view");
    // },
    startDate() {
      //   let membership_level_id = _.get(self.client, "membership_level_id", 0);
      //   if (membership_level_id <= 2 && self.targetClient.rating_level_id >= 2) {
      //     self.$refs.platinumPopup.showModal(3);
      //     return false;
      //   } else if (
      //     membership_level_id <= 3 &&
      //     self.targetClient.is_verified == 1
      //   ) {
      //     self.$refs.diamondPopup.showModal(2);
      //     return false;
      //   }
      console.log("this.targetClient", this.targetClient);
      this.$refs.startDatePopupCom.showModal(this.targetClient);
    },
    // dateAgainClick() {
    //   let self = this;
    //   let membership_level_id = _.get(self.client, "membership_level_id", 0);
    //   //&& self.targetClient.rating_level_id >= 2
    //   if (membership_level_id <= 2) {
    //     self.$refs.platinumPopup.showModal(3);
    //     return false;
    //   } // && self.targetClient.is_verified == 1
    //   else if (membership_level_id <= 3) {
    //     self.$refs.diamondPopup.showModal(2);
    //     return false;
    //   }
    //   //   self.$refs.startDatePopupCom.showModal(self.targetClient);
    //   self.$refs.availabilityVenueList.showModal(
    //     null,
    //     self.targetClientId,
    //     self.targetClient.first_name
    //   );
    // },
    // openVenueSelectPop() {
    //   let self = this;
    //   //   console.log("self.client=", self.client);
    //   //   return false;
    //   //if date not yet started, when "Change" is tapped,
    //   //show select date/time popup and do NOT check membership_level_id.

    //   let date_timestamp = _.get(self.targetIntro, "first_date_timestamp", 0);
    //   let nowSec = Math.round(new Date().getTime() / 1000);
    //   if (date_timestamp > nowSec) {
    //     self.$refs.availabilityVenueList.showModal(
    //       null,
    //       self.targetClientId,
    //       self.targetClient.first_name
    //     );

    //     return false;
    //   }

    //   let membership_level_id = _.get(self.client, "membership_level_id", 0);
    //   if (membership_level_id <= 2 && self.targetClient.rating_level_id >= 2) {
    //     self.$refs.platinumPopup.showModal(2);
    //     return false;
    //   } else if (
    //     membership_level_id <= 3 &&
    //     self.targetClient.is_verified == 1
    //   ) {
    //     self.$refs.diamondPopup.showModal(2);
    //     return false;
    //   }
    //   //   self.$refs.startDatePopupCom.showModal(self.targetClient);
    //   self.$refs.availabilityVenueList.showModal(
    //     null,
    //     self.targetClientId,
    //     self.targetClient.first_name
    //   );

    //   return false;

    //   //   let membership_level_id = _.get(self.client, "membership_level_id", 0);
    //   //   if (!membership_level_id || membership_level_id <= 1) {
    //   //     self.$router.push({
    //   //       name: "payment-plan",
    //   //       params: { planName: "gold" },
    //   //       query: { top_nav: 0 },
    //   //     });

    //   //     return false;
    //   //   }
    //   //todo
    //   //   let dateCredits =
    //   //     _.get(self.client, "date_credits", 0) > 0
    //   //       ? parseInt(_.get(self.client, "date_credits", 0))
    //   //       : 0;
    //   //   if (dateCredits <= 0) {
    //   //     // self.$layer.msg(self.$t("insufficient_date_credit"));
    //   //     //jump to buy date credit page
    //   //     self.$storage.set(
    //   //       "venue-d-user",
    //   //       {
    //   //         client_id: _.get(self.targetClient, "client_id"),
    //   //         first_name: _.get(self.targetClient, "first_name"),
    //   //         age: _.get(self.targetClient, "age"),
    //   //         gender: _.get(self.targetClient, "gender"),
    //   //         photo_url: _.get(self.targetClient, "photo_url"),
    //   //       },
    //   //       60
    //   //     );

    //   //     setTimeout(function () {
    //   //       self.$router.push({ name: "payment-date", query: { top_nav: 0 } });
    //   //     }, 800);

    //   //     return false;
    //   //   }

    //   //   self.$refs.availabilityVenueList.showModal(
    //   //     null,
    //   //     self.targetClientId,
    //   //     self.targetClient.first_name
    //   //   );
    //   //   self.$refs.selectEvent.showModal(0, "eventInvite", self.client);
    // },
    // confirmDate() {
    //   let self = this;
    //   let data = {
    //     venue: self.targetIntro.venue,
    //     dateTime: self.targetIntro.first_date_time,
    //     introId: self.targetIntro.client_intro_id,
    //     targetClientId: self.targetClientId,
    //     statusName: "match_confirmed",
    //     target: {
    //       client_id: _.get(self.targetClient, "client_id"),
    //       first_name: _.get(self.targetClient, "first_name"),
    //       photo_url: _.get(self.targetClient, "photo_url"),
    //     },
    //   };

    //   self.$refs.selectVenueDate.showModal(data);
    // },
    // selectVenueHander(data) {
    //   let self = this;

    //   //   let intro = self.match;

    //   //   if (!intro) {
    //   //     return false;
    //   //   }
    //   //   console.log("data=", data);

    //   self.$refs.availabilityVenueList.closeModel();
    //   data.statusName = "";
    //   //todo
    //   data.target = {
    //     client_id: _.get(self.targetClient, "client_id"),
    //     first_name: _.get(self.targetClient, "first_name"),
    //     photo_url: _.get(self.targetClient, "photo_url"),
    //   };
    //   //   console.log('data', data)
    //   self.$refs.selectVenueDate.showModal(data);
    // },
    // sendInviteHander(data) {
    //   let self = this;

    //   let pdata = new FormData();
    //   //changeDateVenueInvite
    //   pdata.append("intro_id", _.get(data, "introId"));
    //   pdata.append("target_client_id", _.get(data, "targetClientId"));
    //   pdata.append("act_type", _.get(data, "actType", "dateVenueInvite"));
    //   pdata.append("date_time", _.get(data, "dateTime"));
    //   pdata.append("venue_id", _.get(data, "venueId"));
    //   self.$layer.loading();
    //   self.matchAction(
    //     _.get(data, "introId"),
    //     pdata,
    //     _.get(data, "actType", "dateVenueInvite") ////changeDateVenueInvite
    //   );
    // },
    // changeVenueDateHander(data) {
    //   let self = this;
    //   self.$refs.availabilityVenueList.showModal(
    //     null,
    //     self.targetClientId,
    //     self.targetClient.first_name
    //   );
    // },
    // confirmInviteHander(data) {
    //   let self = this;

    //   let membership_level_id = _.get(self.client, "membership_level_id", 0);
    //   //   membership_level_id = 2;
    //   if (!membership_level_id || membership_level_id <= 1) {
    //     self.$router.push({
    //       //   name: "membership-choose",
    //       name: "membership-upgrade",
    //       query: { type: "gold" },
    //       //   name: "payment-plan",
    //       //   params: { planName: "gold" },
    //       //   query: { top_nav: 0 },
    //     });

    //     return false;
    //   }

    //   let cost = parseInt(_.get(data, "dateCreditCost", 0));

    //   let dateCredits =
    //     _.get(self.client, "date_credits", 0) > 0
    //       ? parseInt(_.get(self.client, "date_credits", 0))
    //       : 0;

    //   //   console.log(
    //   //     "dateCredits=",
    //   //     dateCredits,
    //   //     _.get(self.client, "date_credits", 0),
    //   //     data
    //   //   );

    //   if (dateCredits < cost) {
    //     // self.$layer.msg(self.$t("insufficient_date_credit"));
    //     //jump to buy date credit page
    //     self.$storage.set(
    //       "venue-d-user",
    //       _.get(data, {
    //         client_id: _.get(self.targetClient, "client_id"),
    //         first_name: _.get(self.targetClient, "first_name"),
    //         age: _.get(self.targetClient, "age"),
    //         gender: _.get(self.targetClient, "gender"),
    //         photo_url: _.get(self.targetClient, "photo_url"),
    //       }),
    //       60
    //     );

    //     setTimeout(function () {
    //       self.$router.push({ name: "payment-date", query: { top_nav: 0 } });
    //     }, 800);

    //     return false;
    //   }
    //   self.currentDateTimeString = _.get(data, "dateTimeString");
    //   self.currentDateVenue = _.get(data, "venueName");
    //   // return false;
    //   let introId = _.get(self.match, "client_intro_id", 0);
    //   let pdata = new FormData();
    //   //changeDateVenueInvite
    //   pdata.append("intro_id", introId);
    //   pdata.append("target_client_id", _.get(data, "targetClientId"));
    //   pdata.append("act_type", "doubleConfirm");

    //   self.$layer.loading();
    //   self.matchAction(introId, pdata, "doubleConfirm");
    // },
    // confirmDatePopup() {
    //   let self = this;

    //   self.$bvModal
    //     .msgBoxOk(
    //       self
    //         .$t("date_confirmed_text")
    //         .replace("[match_first_name]", self.targetClient.first_name)
    //         .replace("[first_date_time]", self.currentDateTimeString)
    //         .replace("[date_venue_name]", self.currentDateVenue),
    //       {
    //         title: false,
    //         headerClass: "text-secondary",
    //         bodyClass: "text-secondary",
    //         size: "md",
    //         buttonSize: "sm",
    //         okVariant: "primary",
    //         okTitle: self.$t("Ok"),
    //         //   cancelTitle: self.$t("not_now"),
    //         footerClass: "p-2",
    //         hideHeaderClose: true,
    //         centered: true,
    //       }
    //     )
    //     .then((value) => {
    //       // console.log("value=", value);
    //     })
    //     .catch((err) => {
    //       // An error occurred
    //       console.log(err);
    //     });
    // },
    matchAction(intro_id, fdata, type) {
      let self = this;
      // let index = _.findIndex(self.list, { client_intro_id: intro_id });

      self.$api
        .postDateAction(fdata, self.$store.state)
        .then((rs) => {
          // console.log(rs);
          if (rs.success) {
            // console.log("rs", rs);
            let membership_level_id = _.get(
              self.client,
              "membership_level_id",
              0
            );
            if (type == "decline" && membership_level_id <= 1) {
              self.$router.push({
                // name: "membership-choose",
                name: "membership-upgrade",
                query: { type: "gold" },
                // name: "payment-plan",
                // params: { planName: "gold" },
                // query: { top_nav: 0 },
              });

              return false;
            } else {
              if (type == "cancel") {
                self.$layer.msg(
                  self
                    .$t("date_cancelled_desc")
                    .replace("[match_first_name]", self.targetClient.first_name)
                );
                setTimeout(function () {
                  location.reload();
                }, 1500);

                return false;
              }

              if (
                type == "dateVenueInvite" ||
                type == "changeDateVenueInvite"
              ) {
                // self.$layer.closeAll("loading");
                self.$refs.selectVenueDate.closeModel(false);
                self.$refs.inviteConfirmPopup.showModal(self.targetClient);
              } else if (type == "doubleConfirm") {
                //todo
                self.confirmDatePopup();
              } else if (type == "chat_again") {
                location.reload();
                return false;
              }

              setTimeout(function () {
                self.$layer.closeAll("loading");
                self.getClientConversations(self.targetClientId, false);

                if (type == "dateVenueInvite" || type == "doubleConfirm") {
                  self.$refs.selectVenueDate.closeModel(false);
                }
              }, 1000);
            }

            // self.$router.push({ name: "date-1on1" });
            // self.match.source_user_intro_status_id = _.get(
            //   rs,
            //   "newUpdates.source_user_intro_status_id"
            // );
            // self.$refs.selectVenueDate.closeModel(false);
          } else {
            self.$layer.closeAll("loading");
            // if (_.get(rs, "popup_url", false)) {
            //   self.gotoUpgrade(rs);
            // } else {
            self.$layer.msg(_.get(rs, "message", ""));
            // }
          }
        })
        .catch((err) => {
          self.$layer.closeAll("loading");
          //   self.$layer.msg(_.get(err, "response.data.error.body"));
          self.$bvModal.msgBoxOk(_.get(err, "response.data.error.body"), {
            size: "md",
            buttonSize: "sm",
            okVariant: "primary",
            okTitle: self.$t("Ok"),
            hideHeaderClose: true,
            centered: true,
          });
          //self.afterAction(intro_id, type);
          console.log(err);
        });
    },
    changeType(type) {
      let self = this;

      if (self.contacts.length > 0) {
        if (self.contactType == 2) {
          self.skippedContacts = self.contacts;
        } else {
          self.matchedContacts = self.contacts;
        }
      }
      self.contactType = type;
      self.backToList();
      if (type == 2 && self.skippedContacts.length > 0) {
        self.contacts = self.skippedContacts;
      } else if (type == 1 && self.matchedContacts.length > 0) {
        self.contacts = self.matchedContacts;
      } else {
        self.isEnd = false;
        // self.contactType = type;
        // self.backToList();
        self.getMatchContacts();
      }
    },
    toggleEmoji() {
      this.showEmoji = !this.showEmoji;
    },
    addEmo(e) {
      this.message = this.message + e.target.innerHTML;
    },
    goback() {
      //   history.back();
      let ref = this.$storage.get("chat-ref");
      if (ref) {
        location.href = ref;
      } else {
        this.$router.push({ name: "elite-list" });
      }
    },
    sortBySentTime(list) {
      return _.sortBy(list, [
        (o) => {
          return o.sent_time;
        },
      ]).reverse();
    },
    scrollToBottom() {
      let self = this;
      console.log("scrollToBottom");
      if (self.showTargetProfile) {
        return false;
      }
      self.$nextTick(() => {
        setTimeout(function () {
          // console.log("resizeWin-2");
          self.resizeWin();
        }, 400);
        setTimeout(function () {
          let contentBodyEl = $("#contentBody");
          //   console.log(
          //     "scrollHeight",
          //     contentBodyEl,
          //     contentBodyEl[0].scrollHeight
          //   );

          //   var scrollTop = self.$el.querySelector("#contentBody");
          //   console.log("scrollToBottom", scrollTop, scrollTop.scrollHeight);
          //   scrollTop.scrollTop = scrollTop.scrollHeight;
          contentBodyEl.eq(0).scrollTop(contentBodyEl[0].scrollHeight);
          $("#messageInput").focus();
        }, 500);
      });
    },

    backToList() {
      let self = this;

      if (self.client.membership_level_id <= 1) {
        self.$router.push({
          name: "op-match-list",
        });
        return false;
      }

      //   if (self.refPage == "matchmaker-chat") {
      //     // history.back();
      //     console.log('go back')
      //     return false;
      //   }

      //   return false;
      //   console.log("backToList-----1");
      //   self.isMatchmakingChat = false;
      self.showLeftPlan = true;
      self.showTopNav = true;
      self.showRightPlan = false;
      self.targetClientId = 0;
      self.targetClient = null;
      self.conversations = [];
      if (_.get(self.$route, "query.clientId", 0)) {
        self.$router.push({ name: "client-chat" });

        // console.log('go to client-chat')
      }
      //   console.log("backToList-----2");
      setTimeout(function () {
        // self.setRowStyle();
        // console.log("resizeWin-3");
        self.resizeWin();
        // console.log("backToList-----3");
      }, 0);
    },
    async chatToEmployee() {
      let self = this;
      console.log("chatToEmployee");
      //   if (self.client.membership_level_id < 3) {
      //     self.$refs.diamondPopup.showModal();
      //     return false;
      //   }
      //   if (self.client.membership_level_id < 2) {
      //     self.getGoldPayment(2);
      //     return false;
      //   }

      if (!self.client.tencent_im_groupID) {
        self.$layer.loading();
        await self.$api
          .getApplyCreateTIMGroup("", self.$store.state)
          .then((rs) => {
            self.$layer.closeAll("loading");
            console.log("getApplyCreateTIMGroup...", rs);
            if (_.get(rs, "success", false)) {
              self.client.tencent_im_groupID = _.get(rs, "group_info.GroupId");
            }
          });
      }
      //   console.log("get message list...");
      self.targetClient = self.myEmployee;
      self.targetClientId = self.employeeTag;
      self.showLeftPlan = false;
      self.showTopNav = false;
      self.showRightPlan = true;
      self.setCurrentTIMConversationID();

      if (self.isTIMSdkReady) {
        self.getTIMMessageList();
      }
      //

      self.$router.push({
        name: "client-chat",
        query: { clientId: helper.lshash.encode(self.employeeTag) },
      });

      self.showTargetProfile = false;
      //   if (self.$refs.profileViewCom) {
      //     self.$refs.profileViewCom.hideView();
      //   }

      self.getClientConversations(0, true);
      setTimeout(function () {
        // console.log("resizeWin-4");
        self.resizeWin();
      }, 100);
    },
    showTargetPrifile(clientId) {
      let self = this;
      self.$layer.loading();
      self.$api
        .getClient(
          `id=${helper.lshash.encode(clientId)}&from=tim_chat`,
          self.$store.state
        )
        .then((rs) => {
          self.$layer.closeAll("loading");
          self.targetProfile = rs.client;
          self.showTargetProfile = true;

          //   self.targetClient = target;
          //   self.targetClientId = "view";
          //   //   self.showLeftPlan = false;
          //   //   self.showTopNav = false;
          //   self.showRightPlan = true;
          // self.$refs.profileViewCom.showView(self.targetProfile);

          //   self.$router.push({
          //     name: "client-chat",
          //     query: { clientId: "view" },
          //   });

          //   self.resizeWin();
        });
    },
    checkTargetClientExpired() {
      let self = this;

      if (_.get(self.targetClient, "membership_has_expired") == 1) {
        self.canChat = false;
        self.targetClientExpired = 1;
      }
    },
    checkClientExpired() {
      let self = this;

      if (self.targetClientId > 0 && self.client.membership_has_expired == 1) {
        self.canChat = false;
        self.clientExpired = 1;
      } else {
        self.clientExpired = 0;
      }
      //test
      // self.canChat = false
      //   self.clientExpired = 1;

      //   self.y_member_expired = $t('y_member_expired').replace('text-ls-red3', '')
    },
    chatToClient(item) {
      let self = this;
      let vprofile = 0;
      console.log("chatToClient", item);

      let index = _.findIndex(self.contacts, {
        target_client_id: _.get(item, "target_client_id"),
      });
      if (index > -1) {
        self.contacts[index]["unread_count"] = 0;
      }
      //   console.log("item", item);
      //   let nowSec = Math.round(new Date().getTime() / 1000);

      //   console.log(
      //     item.first_date_timestamp,
      //     nowSec,
      //     item.first_date_timestamp - nowSec
      //   );

      //   if (
      //     (item.first_date_timestamp == 0 ||
      //       item.first_date_timestamp - nowSec > 0) &&
      //     _.get(item, "target.client_id") > 0
      //   ) {

      // self.showTargetPrifile(target);
      // self.$router.push({
      //   name: "profile-view",
      //   params: { clientId: _.get(target, "client_id") },
      // });
      // return false;

      //     vprofile = 1;
      //     self.showTargetPrifile(_.get(item, "target.client_id"));
      //   } else {
      //   self.showTargetProfile = false;
      //   if (self.$refs.profileViewCom) {
      //     self.$refs.profileViewCom.hideView();
      //   }
      //   }
      //   console.log("sdfsd");
      self.canChat = _.get(item, "can_chat", true);
      self.messagePage = 1;
      self.conversations = [];
      self.targetClient = _.get(item, "target");
      self.targetClientId = _.get(item, "target_client_id");
      //   self.targetClientEnId = _.get(self.targetClient, "en_id");
      console.log("chatToClient=", self.targetClientId);

      //   if (self.targetClientId == self.matchmakerChatId) {
      //     self.isMatchmakingChat = true;
      //   } else {
      //     self.isMatchmakingChat = false;
      //   }
      self.showLeftPlan = false;
      self.showTopNav = false;
      self.showRightPlan = true;
      self.setCurrentTIMConversationID();
      self.checkClientExpired();
      //

      if (self.isTIMSdkReady) {
        self.getTIMMessageList();
      } else {
        setTimeout(function () {
          self.getTIMMessageList();
        }, 1000);
      }

      if (self.imMode == "tim") {
        self.setTIMMessageRead();
      }

      self.getChatTarget();

      self.$router.push({
        name: "client-chat",
        query: {
          clientId: helper.lshash.encode(self.targetClientId),
          view: vprofile,
        },
      });
      //   console.log("sdfsd...2");

      //   self.getClientConversations(self.targetClientId, false);
      //   console.log("resizeWin-5");
      self.resizeWin();
      //   setTimeout(function () {
      //     self.resizeWin();
      //   }, 100);
    },
    setUnread(type) {
      let self = this;
      if (!self.targetClientId || self.targetClientId == self.employeeTag) {
        return false;
      }

      let index = _.findIndex(self.contacts, {
        target_client_id: self.targetClientId,
      });

      if (index > -1) {
        if (type == "clear") {
          if (_.get(self.$refs, "topName")) {
            self.$refs.topName.setClientUnread(
              "clear",
              self.contacts[index]["unread_count"]
            );
          }

          self.contacts[index]["unread_count"] = 0;
          self.contacts[index]["is_read"] = 1;
        } else {
          if (_.get(self.$refs, "topName")) {
            self.$refs.topName.setClientUnread("add", 1);
          }
          self.contacts[index]["unread_count"] += 1;
          self.contacts[index]["is_read"] = 0;
        }
      }
    },
    sendMsg() {
      let self = this;
      //   console.log(
      //     "message=",
      //     self.message,
      //     "targetClientId=",
      //     self.targetClientId
      //   );
      //
      //   if (
      //     self.targetClientId > 0 &&
      //     self.conversations.length == 0 &&
      //     self.TIMMessageList.length == 0
      //   ) {
      //     self.chatGreetingPopup();
      //     // if (_.get(self.targetClient, "is_verified", 0) == 0) {
      //     //   self.chatGreetingPopup();
      //     // } else {
      //     //   self.openVenueSelectPop();
      //     // }

      //     return false;
      //   }
      self.showEmoji = false;
      if (self.isSending) {
        return false;
      }
      if ((!self.message && !self.tplMessage) || !self.targetClientId) {
        return false;
      }

      self.isSending = true;
      if (self.imMode == "tim") {
        console.log("sentTIMTextMsg");
        self.sentTIMTextMsg(null, self.message);
        return false;
      } else {
        if (self.targetClientId > 0) {
          // console.log(self.socket);
          self.socket.emit("message", {
            modeType: "c2c",
            fromClientId: self.userInfo.client_id,
            toClientId: self.targetClientId,
            message: escape(self.message),
            cityId: self.userInfo.city_id,
            extras: null,
            autoInsert: false,
          });

          let i = _.findIndex(self.contacts, {
            target_client_id: self.targetClientId,
          });
          if (i > -1) {
            self.contacts[i]["unread_count"] = 0;
            self.contacts[i]["is_read"] = 1;
            self.contacts[i]["extras"] = null;
            self.contacts[i]["msg"] = self.message;
            self.contacts[i]["sent_time"] = parseInt(
              new Date().getTime() / 1000
            );
            self.contacts[i]["type"] = 1;
            self.contacts = self.sortBySentTime(self.contacts);
          }
        } else if (self.targetClientId == self.employeeTag) {
          self.socket.emit("message", {
            modeType: "c2e",
            fromClientId: self.userInfo.client_id,
            toClientId: self.targetClientId,
            message: self.message
              ? escape(self.message)
              : escape(self.tplMessage),
            cityId: self.userInfo.city_id,
            extras: null,
            autoInsert: false,
          });

          // self.employeeContacts[0]["unread_count"] = 0;
          // self.employeeContacts[0]["is_read"] = 1;
          // self.employeeContacts[0]["extras"] = null;
          // self.employeeContacts[0]["msg"] = self.message;
          // self.employeeContacts[0]["sent_time"] = parseInt(
          //   new Date().getTime() / 1000
          // );
          // self.employeeContacts[0]["type"] = 1;
        }

        //   self.postClientMessage();

        self.conversations.push({
          type: 1,
          msg: self.message,
          to_client_id: self.targetClientId,
          from_client_id: self.userInfo.client_id,
          sent_time: new Date().toString(),
        });
        if (self.imMode == "websocket") {
          self.saveMessageToServer();
        }
        self.$refs.msgInput.clearText();
        self.message = "";

        self.isSending = false;

        self.scrollToBottom();
      }
    },
    postClientMessage() {
      let self = this;

      self.$layer.loading();
      let pdata = new FormData();
      pdata.append("to_client_id", self.targetClientId);
      pdata.append("message", self.message);

      self.$api
        .postClientMessage(pdata, self.$store.state)
        .then((rs) => {
          self.$layer.closeAll("loading");
          console.log("rs", rs);
        })
        .catch((err) => {
          self.$layer.closeAll("loading");
          console.log("err", err);
        });
    },
    getClientConversations(targetClientId, forEmployee) {
      let self = this;
      //   console.log(
      //     "getClientConversations",
      //     self.messagePage,
      //     targetClientId,
      //     forEmployee
      //   );
      self.$layer.loading();
      self.loadingConversation = true;

      //   self.targetIntro = null;
      //   self.offMessageListScroll();
      self.$api
        .getClientConversations(
          `target_client_id=${helper.lshash.encode(
            targetClientId
          )}&order=desc&for_employee=${forEmployee}&use_page=1&page=${self.messagePage
          }&page_size=${self.messagePageSize}`,
          self.$store.state
        )
        .then((rs) => {
          self.$layer.closeAll("loading");

          //   if (!self.showTargetProfile && self.$refs.profileViewCom) {
          //     self.$refs.profileViewCom.hideView();
          //   }

          if (self.messagePage == 1) {
            self.lastChatSentTime = 0;
            self.TIMMessageList = [];
            self.conversations = [];
          }

          self.loadingConversation = false;

          if (!self.targetClient && _.get(rs, "target_client")) {
            self.targetClient = _.get(rs, "target_client");
            // if (self.imMode == "tim") {
            self.setCurrentTIMConversationID();

            self.checkTargetClientExpired();

            // //   self.getTIMMessageList();
          }

          self.checkClientExpired();
          //   }
          //   self.canChat = _.get(rs, "can_chat", true);
          if (self.imMode == "websocket") {
            self.buttons = _.get(rs, "buttons");
            self.targetIntro = _.get(rs, "client_intro");
            self.firstLabelText = _.get(rs, "label");
            self.dateTitle = _.get(rs, "date_title");
            self.labelColor = _.get(rs, "label_color");
            self.labelArray = _.get(rs, "label_array");
          }

          self.isChatExpried = _.get(rs, "is_chat_expired");
          self.isChatConfirmed = _.get(rs, "is_date_confirmed");
          //   self.isChatConfirmed = 1;

          //   if (self.isChatConfirmed) {
          // let time_array = [
          //   { text: "Date on ", color: "#8d8d8d", bold: "0" },
          //   { text: "12 ", color: "#13bd50", bold: "1" },
          //   { text: "Nov ", color: "#8d8d8d", bold: "0" },
          //   { text: "15:00", color: "#13bd50", bold: "1" },
          //   { text: " @ Hong Kong", color: "#8d8d8d", bold: "0" },
          // ];
          // self.dateTimeHtml = self.showHtmlLable(time_array);
          self.dateTimeHtml = self.showHtmlLable(
            _.get(rs, "client_intro.date_time_array")
          );
          self.matchTimeHtml = self.showHtmlLable(
            _.get(rs, "client_intro.match_time_array")
          );
          //   }

          // self.labelText = self.labelText.replace('[line]', '')
          //   self.canChat = false;
          //   self.isChatExpried = 1;

          self.secLeft = parseInt(_.get(rs, "time_left", 0));
          self.chatCloseLeftSec = parseInt(_.get(rs, "chat_close_left", 0));
          //   console.log("self.secLeft=", self.secLeft);
          if (self.secLeft > 0) {
            // self.countdownLeftTime();
          } else {
            self.labelText = _.get(rs, "label")
              .replace(/\r\n/g, "<br/>")
              .replace(/\n/g, "<br/>");
          }

          //   if (self.chatCloseLeftSec > 0) {
          //     self.countdownLeftTime2();
          //   }
          //   console.log("messages size=", _.size(..._.get(rs, "messages", [])));
          if (_.size(..._.get(rs, "messages", [])) < self.messagePageSize) {
            self.seeMoreMessage = false;
          }

          let sortedMessages = _.sortBy(_.get(rs, "messages", []), [
            function (o) {
              return o.sent_time;
            },
          ]);
          sortedMessages = self.setTimeShow(sortedMessages);
          //   console.log('sortedMessages', sortedMessages)

          self.conversations = [...sortedMessages, ...self.conversations];
          // console.log(self.conversations)
          //   if (
          //     !self.canChat &&
          //     self.imMode == "tim" &&
          //     self.targetClientId == self.employeeTag
          //   ) {
          //     let lastMsg = _.last(self.conversations);
          //     // console.log("lastMsg", lastMsg);
          //     if (lastMsg) {
          //       self.groupLastMsg = lastMsg;
          //     }
          //   }
          //   console.log(
          //     "test=====",
          //     self.targetClientId,
          //     self.conversations.length
          //   );
          //   self.chatGreetingPopup();
          //2021.09.15
          //if neither side has sent any message yet,
          //then show chat greeting popup with message "Your chat with Amy will expire in 23:12:59. Send a greeting message now!"
          //   if (
          //     self.canChat &&
          //     self.targetClientId > 0 &&
          //     self.conversations.length == 0
          //   ) {
          //     self.chatGreetingPopup();
          //     // if (_.get(self.targetClient, "is_verified", 0) == 0) {
          //     //   self.chatGreetingPopup();
          //     // } else {
          //     //   self.openVenueSelectPop();
          //     // }
          //   }
          //   console.log("conversations", self.conversations);
          //   self.setUnread("clear");
          if (forEmployee) {
            return self.$api.getClientAutoChat("", self.$store.state);
          }
          setTimeout(function () {
            // console.log("resizeWin-6");
            self.resizeWin();

            if (self.canChat) {
              $("#msgBottom").css({
                height: `${$(".chat-input-box").outerHeight(true) + 20}px`,
              });
              //   console.log(
              //     "set 0",
              //     `${$(".chat-input-box").outerHeight(true) + 30}px`
              //   );
            } else {
              $("#msgBottom").css({
                height: `${$(".labelButtonRow").outerHeight(true) + 20}px`,
              });
              //   console.log(
              //     "set 1",
              //     `${$(".labelButtonRow").outerHeight(true) + 30}px`
              //   );
            }
          }, 800);

          if (self.messagePage == 1) {
            self.scrollToBottom();
          }

          if (self.messagePage == 1 && forEmployee == 1) {
            setTimeout(function () {
              self.messageListScroll();
            }, 500);
          }

          self.messagePage++;
        })
        .then((rs) => {
          let autoChat = _.get(rs, "chat");
          if (_.get(autoChat, "type") == 1 && _.get(autoChat, "msg")) {
            self.tplMessage = _.get(autoChat, "msg", "");
            // self.sendTplMessage({ tpl_text: _.get(autoChat, "msg", "") });
            self.sendMsg();
          }

          if (self.messagePage == 1) {
            setTimeout(function () {
              self.messageListScroll();
            }, 500);
          }

          if (self.messagePage == 1) {
            self.scrollToBottom();
          }
          self.messagePage++;
        })
        .catch((err) => {
          self.loadingConversation = false;
          self.$layer.closeAll("loading");
          //   self.setUnread("clear");
          console.log("err", err);
        });
    },
    setTimeShow(messageList) {
      // console.log('messageList', messageList)
      let self = this;
      let newMessageList = [];
      let filterList = messageList.filter((item) => {
        return !item.showTime || item.showTime === false;
      });
      //   console.log("filterList", filterList);

      filterList.forEach((item, index) => {
        let sentTime = item.sent_time || item.time;

        if (!self.lastChatSentTime && index == 0) {
          self.lastChatSentTime = sentTime;
          item.showTime = true;
          item.prettyTime = helper.prettyChatTime(sentTime, true);
        } else {
          let diffM = sentTime - self.lastChatSentTime;
          if (diffM <= 300) {
            item.showTime = false;
          } else {
            self.lastChatSentTime = sentTime;
            item.showTime = true;
            item.prettyTime = helper.prettyChatTime(sentTime, true);
          }
        }
        // console.log('item', item)
        newMessageList.push(item);
      });

      return newMessageList;
    },
    chatGreetingPopup() {
      let self = this;

      self.$refs.startDatePopupCom.showModal(self.targetClient);
      return false;

      //   let title = self.$t("chat_greeting_popup_title");
      //   self.$bvModal
      //     .msgBoxOk(
      //       title.replace("[match_first_name]", self.targetClient.first_name),
      //       {
      //         title: false,
      //         headerClass: "text-secondary",
      //         bodyClass: "text-secondary",
      //         size: "md",
      //         buttonSize: "sm",
      //         okVariant: "primary",
      //         okTitle: self.$t("Ok"),
      //         //   cancelTitle: self.$t("not_now"),
      //         footerClass: "p-2",
      //         hideHeaderClose: true,
      //         centered: true,
      //       }
      //     )
      //     .then((value) => {
      //       // console.log("value=", value);
      //     })
      //     .catch((err) => {
      //       // An error occurred
      //       console.log(err);
      //     });
    },
    // countdownLeftTime2() {
    //   let self = this;
    //   if (self.countdownLeftTimer2) {
    //     clearInterval(self.countdownLeftTimer2);
    //     self.countdownLeftTimer2 = null;
    //   }

    //   self.countdownLeftTimer2 = setInterval(function () {
    //     self.chatCloseLeftSec--;

    //     if (self.chatCloseLeftSec < 0) {
    //       self.chatCloseText = null;
    //       self.canChat = false;
    //       clearInterval(self.countdownLeftTimer2);
    //       self.countdownLeftTimer2 = null;
    //       self.getClientConversations(self.targetClientId, false);
    //     } else {
    //       let str = self.setLeftSec2(self.chatCloseLeftSec, false, true);
    //       self.chatCloseText = self.chatCloseDesc.replace("[left_time]", str);
    //       //   self.chatCloseText = `This chat line is closing in ${str}`;
    //       //   self.chatCloseText = self.chatCloseText.replace(
    //       // /\(.*\)/,
    //       // `(<span class="text-danger">${str}</span>)`
    //       //   );
    //     }
    //   }, 1000);
    // },
    // countdownLeftTime() {
    //   let self = this;
    //   if (self.countdownLeftTimer) {
    //     clearInterval(self.countdownLeftTimer);
    //     self.countdownLeftTimer = null;
    //   }

    //   self.countdownLeftTimer = setInterval(function () {
    //     self.secLeft--;
    //     if (self.secLeft < 0) {
    //       clearInterval(self.countdownLeftTimer);
    //       self.countdownLeftTimer = null;
    //     } else {
    //       let str = self.setLeftSec2(self.secLeft, false, true);
    //       self.labelText = self.firstLabelText
    //         .replace(/\r\n/g, "<br/>")
    //         .replace(/\n/g, "<br/>")
    //         .replace(
    //           "[left_time]",
    //           str
    //           //   `<span class="text-danger ml-1">${str}</span>`
    //         );
    //       //   self.labelText = self.labelText.replace(
    //       //     /\(.*\)/,
    //       //     `(<span class="text-danger">${str}</span>)`
    //       //   );
    //     }
    //   }, 1000);
    // },
    // setLeftSec2(leftSec, abs, showHour) {
    //   //   let self = this;

    //   if (abs) {
    //     leftSec = Math.abs(leftSec);
    //   }
    //   //   console.log("self.secLeft=", self.secLeft);
    //   if (isNaN(leftSec) && leftSec <= 0) {
    //     return "";
    //   }
    //   let hours = 0;
    //   if (showHour) {
    //     hours = Math.floor(leftSec / 3600);
    //   }
    //   let minutes = Math.floor((leftSec - hours * 3600) / 60);
    //   let sec = leftSec - hours * 3600 - minutes * 60;

    //   let str = `${minutes >= 10 ? minutes : "0" + minutes}:${
    //     sec >= 10 ? sec : "0" + sec
    //   }`;

    //   if (showHour) {
    //     str = `${hours >= 10 ? hours : "0" + hours}:${str}`;
    //   }

    //   //   if (hours < 0) {
    //   //     console.log("con", hours, minutes, sec, str);
    //   //   }

    //   return str;
    //   //   console.log("left sec: ", str);
    // },
    // setLeftSec() {
    //   let self = this;
    //   console.log("self.secLeft=", self.secLeft);
    //   if (isNaN(self.secLeft) && self.secLeft <= 0) {
    //     return false;
    //   }
    //   let hours = Math.floor(self.secLeft / 60);
    //   let minutes = Math.floor((self.secLeft - hours * 3600) / 60);

    //   self.labelText.replace("[hour_num]", hours).replace("[min_num]", minutes);
    // },
    clearSearch() {
      let self = this;
      self.isEnd = false;
      self.listQuery.page = 1;
      self.listQuery.search_text = "";

      if (self.imMode == "tim") {
        self.loopTIMConversationList(1000);
      } else {
        self.contacts = [];
        //   console.log("self.listQuery=", self.listQuery);
        //   return false;
        self.getMatchContacts();
      }
    },
    goSearch() {
      let self = this;
      let text = $.trim(self.listQuery.search_text);
      if (!text) {
        console.log("no text");
        return false;
      }

      self.isEnd = false;
      self.listQuery.page = 1;
      self.listQuery.search_text = text;

      if (self.imMode == "tim") {
        self.loopTIMConversationList(1000);
      } else {
        self.contacts = [];
        //   console.log("self.listQuery=", self.listQuery);
        //   return false;
        self.getMatchContacts();
      }
    },
    getMatchContacts() {
      let self = this;

      //   self.contacts = [];
      if (self.isEnd || self.isLoadingContact) {
        console.log("is end=", self.isEnd);
        return false;
      }
      self.$layer.loading();
      self.isLoadingContact = true;
      let query = _.map(self.listQuery, function (value, key) {
        return `${key}=${value}`;
      }).join("&");

      self.$api
        .getClientMatchContacts(query, self.$store.state)
        .then((rs) => {
          self.$layer.closeAll("loading");
          self.isLoadingContact = false;
          let len = _.get(rs, "contacts", []).length;

          if (
            self.employeeContacts.length == 0 &&
            _.size(rs, "employee_contacts") > 0
          ) {
            self.hasContacts = true;
            self.employeeContacts = _.get(rs, "employee_contacts");
          }

          //   self.myEmployee = {
          //     first_name: _.get(rs, "employee.name", ""),
          //     photo_url: _.get(rs, "employee.photo_url", ""),
          //     name_icon: _.get(rs, "employee.name_icon", ""),
          //   };
          //   len = 0;

          self.listItemLen += len;
          if (len > 0) {
            self.hasContacts = true;

            self.contacts = [...self.contacts, ..._.get(rs, "contacts", [])];
            // self.contacts = _.sortBy(self.contacts, [
            //   function (o) {
            //     return -o.sent_time;
            //   },
            // ]);
            // self.getDateStartIndex();

            // setTimeout(function () {
            //   self.runCountDownDateStart();
            // }, 1000);

            // console.log("dddd", self.listQuery.page, self.targetClientId);
            if (
              self.listQuery.page == 1 &&
              !self.targetClientId &&
              $(window).width() >= 576
            ) {
              self.chatToClient(self.contacts[0]);
            }

            if (self.listQuery.page == 1) {
              self.chatListScroll();
            }
            self.listQuery.page++;
          } else {
            // self.contacts = [];
            self.isEnd = true;
            if (self.listQuery.page == 1) {
              self.noDatesFound();
            }
          }
        })
        .catch((err) => {
          self.isLoadingContact = false;
          self.$layer.closeAll("loading");
          console.log("err", err);
        });
    },
    getDateStartIndex() {
      let self = this;
      let dateStartIndex = [];
      for (var i in self.contacts) {
        // self.contacts[i].date_completed = false;
        // self.contacts[i].date_start_in_text = null;
        // if (self.contacts[i].date_start_in > 0) {
        //   if (
        //     self.contacts[i]["intro"]["source_intro_status_id"] >= 3 &&
        //     self.contacts[i]["intro"]["target_intro_status_id"] >= 3
        //   ) {
        //     self.contacts[i]["date_start_in_text"] = self.dateCompletedText;
        //     self.contacts[i].date_completed = true;
        //     self.contacts[i]["color"] = "text-success";
        //   } else {
        //   self.contacts[i]["date_start_in_text"] = self.dateExpiredText;
        //   self.contacts[i]["color"] = "text-ls-red";
        //   self.contacts[i].date_expired = true;
        //   }
        //   self.contacts[i].date_start_in_text = self.dateCompletedText;
        //   continue;
        // }
        let label = self.contacts[i]["label"];
        self.contacts[i]["label_text"] = null;

        if (label && label.indexOf("[left_time]") == -1) {
          self.contacts[i]["label_text"] = label;
        }

        if (
          label.indexOf("[left_time]") > -1 &&
          self.contacts[i].first_date_timestamp > 0
        ) {
          dateStartIndex.push(self.contacts[i].target_client_id);
        }
      }

      self.dateStartIndex = _.uniq(dateStartIndex);
      console.log("self.dateStartIndex=", self.dateStartIndex);
    },
    // runCountDownDateStart() {
    //   let self = this;

    //   if (self.dateStartTimer) {
    //     clearInterval(self.dateStartTimer);
    //     self.dateStartTimer = null;
    //   }
    //   self.dateStartTimer = setInterval(function () {
    //     self.countDownDateStart();
    //   }, 1000);
    // },
    // countDownDateStart() {
    //   let self = this;
    //   //   console.log("run countDownDateStart");
    //   if (self.dateStartIndex.length == 0) {
    //     console.log("dateStartIndex empty");
    //     if (self.dateStartTimer) {
    //       clearInterval(self.dateStartTimer);
    //       self.dateStartTimer = null;
    //     }

    //     return false;
    //   }
    //   let nowSec = Math.round(new Date().getTime() / 1000);
    //   let dateTime = 72 * 3600;
    //   for (var item of self.dateStartIndex) {
    //     let i = _.findIndex(self.contacts, { target_client_id: item });
    //     if (i > -1) {
    //       if (!_.has(self.contacts, `${i}.first_date_timestamp`)) {
    //         console.log("target_client_id has no first_date_timestamp", item);
    //         continue;
    //       }

    //       let date_start_in =
    //         self.contacts[i]["first_date_timestamp"] + dateTime - nowSec;
    //       let label = self.contacts[i]["label"];

    //       //   if (item == 137726) {
    //       //     console.log("label", label);
    //       //   }

    //       if (date_start_in > 0) {
    //         // self.contacts[i]["date_start_in"] = date_start_in;
    //         //   nowSec - self.contacts[i]["first_date_timestamp"] - 72 * 3600; //259200

    //         //started & not expired
    //         if (date_start_in <= dateTime) {
    //           if (item == self.targetClientId && !self.canChat) {
    //             if (!self.buttons) {
    //               self.canChat = true;
    //             }

    //             // self.targetProfile = null;
    //             // self.showTargetProfile = false;
    //             // if (self.$refs.profileViewCom) {
    //             //   self.$refs.profileViewCom.hideView();
    //             // }

    //             if (self.canChat) {
    //               self.chatCloseLeftSec = dateTime;
    //               self.countdownLeftTime2();
    //             }

    //             setTimeout(function () {
    //               self.resizeWin();
    //             }, 200);
    //           }

    //           let str = self.setLeftSec2(date_start_in, true, true);
    //           self.contacts[i]["can_chat"] = true;
    //           self.contacts[i]["label_color"] = "red";
    //           //   self.contacts[i][
    //           //     "date_start_in_text"
    //           //   ] = self.dateStartedFor.replace("[left_time]", str);
    //           self.contacts[i]["label_text"] = label.replace(
    //             "[left_time]",
    //             str
    //           );
    //         } //not yet start
    //         else {
    //           self.contacts[i]["label_color"] = "#169f46";

    //           date_start_in = nowSec - self.contacts[i]["first_date_timestamp"];

    //           self.contacts[i]["can_chat"] = false;
    //           let str = self.setLeftSec2(date_start_in, true, true);

    //           //   if (item == 137726) {
    //           //     console.log("str", str);
    //           //   }

    //           self.contacts[i]["label_text"] = label.replace(
    //             "[left_time]",
    //             str
    //           );
    //         }
    //       } //expired
    //       else {
    //         if (item == self.targetClientId && self.canChat) {
    //           self.chatCloseText = null;
    //           self.canChat = false;
    //           // clearInterval(self.countdownLeftTimer2);
    //           self.showTargetProfile = true;
    //           //   self.$refs.profileViewCom.showView(self.targetProfile);
    //           self.getClientConversations(self.targetClientId, false);
    //         }

    //         self.contacts[i]["can_chat"] = false;

    //         // if (
    //         //   self.contacts[i]["intro"]["source_intro_status_id"] >= 3 &&
    //         //   self.contacts[i]["intro"]["target_intro_status_id"] >= 3
    //         // ) {
    //         //   self.contacts[i]["date_start_in_text"] = self.dateCompletedText;
    //         //   self.contacts[i]["date_completed"] = true;
    //         //   self.contacts[i]["color"] = "text-success";
    //         // } else {
    //         self.contacts[i]["label_text"] = self.dateExpiredText;

    //         self.contacts[i]["label_color"] = "red";
    //         self.contacts[i]["date_expired"] = true;
    //         // }

    //         self.dateStartIndex = _.pull(self.dateStartIndex, item);
    //       }
    //     } else {
    //       console.log("can not found", item);
    //       self.contacts[i]["date_start_in"] = 0;
    //       self.dateStartIndex = _.pull(self.dateStartIndex, item);
    //     }
    //     self.$forceUpdate();
    //     // console.log(
    //     //   "date_start_in_text",
    //     //   item,
    //     //   self.contacts[i]["date_start_in_text"]
    //     // );
    //   }
    // },
    getContacts() {
      let self = this;
      if (self.isEnd) {
        console.log("is end=", self.isEnd);
        return false;
      }
      self.isLoadingContact = true;
      self.$layer.loading();
      self.$api
        .getClientContacts(`page=${self.page}`, self.$store.state)
        .then((rs) => {
          self.$layer.closeAll("loading");
          let len = _.get(rs, "contacts", []).length;
          if (
            self.employeeContacts.length == 0 &&
            _.size(rs, "employee_contacts") > 0
          ) {
            self.hasContacts = true;
            self.employeeContacts = _.get(rs, "employee_contacts");
          }

          if (len > 0) {
            self.hasContacts = true;
            self.contacts = [...self.contacts, ..._.get(rs, "contacts")];
            if (self.page == 1) {
              self.checkContacts();
            }

            self.page++;
            self.setRowStyle();
          } else {
            self.isEnd = true;
          }
          self.isLoadingContact = false;
        })
        .catch((err) => {
          self.$layer.closeAll("loading");
          console.log("err", err);
        });
    },
    addContact(targetClientId) {
      let pdata = new FormData();
      pdata.append("target_client_id", targetClientId);

      this.$api.postAddContact(pdata, this.$store.state);
    },
    removeContact(targetClientId) {
      let pdata = new FormData();
      pdata.append("target_client_id", targetClientId);

      this.$api.postRemoveContact(pdata, this.$store.state);
    },
    displayPlan() {
      let self = this;
      //   console.log("displayPlan", self.targetClientId);
      //   if (self.targetClientId == self.employeeTag) {
      //     self.showLeftPlan = false;
      //     self.showTopNav = false;
      //     self.showRightPlan = true;

      //     return false;
      //   }

      let wW = $(window).width();

      if (wW >= 576) {
        self.showLeftPlan = true;
        self.showTopNav = true;
        self.showRightPlan = true;
      } else {
        if (self.targetClientId) {
          self.showLeftPlan = false;
          self.showTopNav = false;
          self.showRightPlan = true;
        } else {
          self.showLeftPlan = true;
          self.showTopNav = true;
          self.showRightPlan = false;
        }
      }
    },
    setRowStyle() {
      let self = this;
      if ($(window).width() >= 576) {
        return false;
      }
      let l_span_w = $("#listContent").width();
      let n = 6 + 66;

      if (l_span_w > 0) {
        self.rowStyle = {
          width: `${l_span_w - n - 40}px`,
          "min-width": `${l_span_w - n - 40}px`,
        };
      } else {
        self.rowStyle = {};
      }
    },
    resizeWin() {
      let self = this;
      let wW = $(window).width();
      let wH = $(window).height();
      let listContent = $("#listContent");
      //   console.log("can chat", self.canChat);
      // console.log("wW=", wW, "wH=", wH, listContent.height());
      //   console.log($("#contentBox").length);
      //   console.log("userInfo", self.userInfo);
      console.log("resizeWin");
      this.displayPlan();
      let topnavH = 0;
      let l_span_w = $("#listContent").width();
      let n = 48 + 66;
      if (wW >= 576) {
        self.showReportBlockBtn = false;
        n += 10;
        // self.rowStyle = { "width": `100%` };
        self.rowStyle = {
          width: `${l_span_w - n}px`,
          "min-width": `${l_span_w - n}px`,
        };
        let contentW = $("#contentBox").outerWidth(true);

        $(".chat-input-box").css({ width: `${contentW}px` });
        // console.log("ww", contentW);
        if ($(".labelButtonRow").length > 0) {
          $(".labelButtonRow").css({ width: `${contentW}px` });
        }

        self.listContentClass = "border-right overflow-auto";
        if (wW <= 768) {
          self.listContentStyle = { height: `${wH - 56}px` };
        } else {
          topnavH = 90;
          self.listContentStyle = { height: `${wH - 72}px` };
        }
        // if (self.targetClientId == self.employeeTag) {
        //   topnavH = 0;
        // }

        self.contentBoxStyle = null;

        // $("#listContent")
        //   .addClass("border-right overflow-auto")
        //   .css({ height: `${wH}px` });
      } else {
        self.showReportBlockBtn = true;

        self.rowStyle = {
          width: `${l_span_w - n}px`,
          "min-width": `${l_span_w - n}px`,
        };
        $(".chat-input-box").css({ width: `${wW}px` });
        if ($(".labelButtonRow").length > 0) {
          $(".labelButtonRow").css({ width: `${wW}px` });
        }
        self.listContentClass = "";
        // listContent.removeClass("border-right overflow-auto");
        // self.contentBoxStyle = { width: `100%` };
        let lsitH = listContent.height(); //
        if (typeof lsitH == "undefined") {
          lsitH = 0;
        }
        // if (lsitH >= 0 && lsitH < wH) {
        // self.listContentStyle = { height: `${wH - 57}px` };
        // let barH = $(".pageTitleBar").outerHeight(true);
        //- (barH > 0 ? barH : 49)
        let navH = $("#topNav").outerHeight(true);
        self.listContentStyle = {
          height: `${wH - (navH > 0 ? navH : 66)}px`,
        };
        // console.log(
        //   "show left",
        //   wH,
        //   $(".pageTitleBar").outerHeight(true),
        //   $("#topNav").outerHeight(true)
        // );
        //   listContent.css({ height: `${wH}px` });
        // }
        // else {
        //   self.listContentStyle = { height: `auto` };
        //   //   listContent.css({ height: `auto` });
        // }
        // listContent.parent("div").css({ width: "100%" });
      }

      let clientHeadHeight = $("#contentHead").outerHeight(true);

      clientHeadHeight = clientHeadHeight > 0 ? clientHeadHeight : 67;
      //   console.log(
      //     "body-height=",
      //     wH,
      //     clientHeadHeight,
      //     topnavH,
      //     wH - clientHeadHeight - topnavH
      //   );
      $("#contentBody").css({
        height: `${wH - clientHeadHeight - topnavH - 57}px`,
      });
      //   self.setRowStyle();
      //   console.log(
      //     "contentBody=",
      //     wH -
      //       $("#contentHead").outerHeight(true) -
      //       $(".chat-input-box").outerHeight(true)
      //   );
      //   if (this.showRightPlan) {
      //     $("#contentBody").css({
      //       height: `${wH - 65 - $(".chat-input-box").outerHeight(true)}px`,
      //     });
      //   }
    },
  },
};
</script>
<style scoped>
body {
  overflow-y: hidden !important;
}

.ls-justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.targetImg {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;
  margin-right: 10px;
}

.chatWin {
  margin-top: 90px;
}

.chatWin.matchmaker {
  margin-top: 0;
}

.redBot {
  position: absolute;
  width: 20px;
  height: 20px;
  /* background: #f52751; */
  background: #ff003b;
  border-radius: 50%;
  top: 44px;
  right: 1px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
}

.headImgBox {
  /* position: relative; */
  width: 50px;
  height: 50px;
  border-radius: 25px;
  overflow: hidden;
  min-width: 50px;
}

.leftPlan,
.rightPlan {
  -webkit-box-flex: 0;
  position: relative;
  width: 100%;
  min-height: 1px;
  float: left;
}

.leftPlan {
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  max-width: 30%;
}

.rightPlan {
  -ms-flex: 0 0 70%;
  flex: 0 0 70%;
  max-width: 70%;
}

.rightPlan.matchmaker {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.labelButtonRow {
  width: 100%;
  position: fixed;
  right: 0;
  bottom: 0;
  background: #fff;
  border-top: 1px solid #ccc;
  z-index: 3;
}

.full-view {
  bottom: 40% !important;
  border: none !important;
}

.rightPlan.matchmaker .chat-input-box {
  width: 100%;
}

.chatListBox {
  border-right: 1px solid #dee2e6;
  overflow-y: auto;
}

.backbtn2 {
  display: none !important;
}

.rightPlan.matchmaker .backbtn2 {
  display: flex !important;
}

.chatCloseText {
  /* position: absolute; */
  width: 100%;
  /* bottom: 10px; */
  text-align: center;
}

.profileCard {
  margin-bottom: 80px;
}

.expired_tag {
  position: absolute;
  bottom: -10px;
  right: 0;
}

@media (max-width: 768px) {
  .leftPlan {
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }

  .rightPlan {
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }

  .chatWin {
    margin-top: 0;
  }
}

@media (max-width: 575.98px) {
  .backbtn2 {
    display: flex !important;
  }

  .chatListBox {
    border: none;
  }

  .leftPlan {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-bottom: 60px;
  }

  .rightPlan {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .chat-input-box,
  .labelButtonRow {
    width: 100%;
  }

  #contentHead {
    position: fixed;
    width: 100%;
    z-index: 9;
  }

  #contentBody {
    /* margin-top: 70px !important; */
  }

  /* .chatCloseText {
    bottom: -26px;
  } */

  .profileCard {
    margin-bottom: 125px;
  }
}

@media (max-width: 440px) {}
</style>